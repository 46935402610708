import React, { useEffect, useState, useRef } from 'react'
import { Form, Input, Modal } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import type { UploadFile, UploadProps } from 'antd/es/upload/interface'
import { useRecoilState, useRecoilValue } from 'recoil'
import { deviceRecoil } from '../../recoils/device'
import { currentOrganizationRecoil } from '../../recoils/organization'
import { Timestamp } from '@firebase/firestore'
import { DEFAULT_AVATAR, getStorageUrl, upload, uploadStorage } from '../../utils'
import UploadImage from '../uploadImage'

const EditDevice: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [uploadError, setUploadError] = useState(false)
  const isError = useRef(false)
  const [isLoading, setIsLoading] = useState(false)

  const { deviceId } = useParams()
  const { organizationId } = useRecoilValue(currentOrganizationRecoil)
  const [device, setDevice] = useRecoilState(deviceRecoil({ organizationId, deviceId }))

  const [name, setName] = useState(device?.client?.current?.name || '')
  const [displayName, setDisplayName] = useState(device?.client?.current?.displayName || '')

  const [file, setFile] = useState<UploadFile | undefined>(undefined)
  const [fileList, setFileList] = useState<UploadFile[]>(
    upload({ uid: device?.id, url: device?.client?.current?.avatar || DEFAULT_AVATAR })
  )

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList, file: newFile }) => {
    newFileList.length !== 0 ? setFile(newFile) : setFile(undefined)
    setFileList(newFileList)
  }

  const handleEditDevice = async () => {
    setIsLoading(true)
    if (uploadError) {
      return
    }

    await Promise.all([uploadStorage({ newFile: file, url: `images/device-avatars/${file?.uid}` })]).then((path) => {
      Promise.all([getStorageUrl(path[0])]).then(async (downloadURLs) => {
        setDevice({
          ...device,
          client: {
            ...device.client,
            current: {
              ...device.client.current,
              avatar: downloadURLs[0],
              name,
              displayName,
              updatedAt: Timestamp.now(),
            },
          },
        })

        navigate('/devices')
      })
    })
  }

  useEffect(() => {
    if (!device && !isError.current) {
      isError.current = true
      window.alert(t('device.message.not-exist'))
      navigate('/devices')
    }
  }, [device, navigate, t])

  return (
    <Modal
      title={t('device.edit-device')}
      centered
      open={true}
      onOk={handleEditDevice}
      onCancel={() => navigate('/devices')}
      okButtonProps={{ disabled: name?.length === 0 || displayName?.length === 0 || uploadError, loading: isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
      bodyStyle={{ padding: '20px 0' }}
      width={400}
    >
      <Form layout="vertical">
        <Form.Item label={t('device.avatar')}>
          <UploadImage fileList={fileList} setUploadError={setUploadError} handleChangeImage={handleChange} />
        </Form.Item>
        <Form.Item label={t('device.device-name')}>
          <Input
            placeholder={t('device.device-name') || ''}
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </Form.Item>
        <Form.Item label={t('device.display-name')}>
          <Input
            placeholder={t('device.display-name') || ''}
            value={displayName}
            onChange={(event) => setDisplayName(event.target.value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EditDevice
