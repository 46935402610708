import React from 'react'
import { Form } from 'antd'
import { UploadFile, UploadProps } from 'antd/es/upload'
import { useTranslation } from 'react-i18next'
import DeviceInformation from './DeviceInformation'
import TextArea from 'antd/es/input/TextArea'
import UploadImage from '../uploadImage'

type PostImageProps = {
  organizationId: string
  deviceIds: string[]
  postDescription: string
  setPostDescription: (value: string) => void
  setPostImage: (image: any) => void
  posted: boolean
  fileList: UploadFile[]
  setFileList: (fileList: UploadFile[]) => void
  setUploadError: (isError: boolean) => void
}

const PostImage: React.FC<PostImageProps> = ({
  organizationId,
  deviceIds,
  postDescription,
  setPostDescription,
  setPostImage,
  posted,
  fileList,
  setFileList,
  setUploadError,
}) => {
  const { t } = useTranslation()

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList, file: newFile }) => {
    if (posted) return
    setPostImage(newFile)
    setFileList(newFileList)
  }

  return (
    <div style={{ padding: '20px' }}>
      {deviceIds.map((deviceId) => (
        <DeviceInformation key={deviceId} organizationId={organizationId} deviceId={deviceId as string} />
      ))}
      <Form layout="vertical" style={{ marginTop: '16px' }}>
        <Form.Item label={t('post.image')}>
          <UploadImage fileList={fileList} setUploadError={setUploadError} handleChangeImage={handleChange} />
        </Form.Item>
        <Form.Item label={t('post.description')}>
          <TextArea
            showCount
            maxLength={30}
            placeholder={t('post.description.placeholder') || ''}
            disabled={posted}
            value={postDescription}
            onChange={(e) => setPostDescription(e.target.value)}
          />
        </Form.Item>
      </Form>
    </div>
  )
}

export default PostImage
