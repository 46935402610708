import React, { useState } from 'react'
import { Button, Input, Modal, message } from 'antd'

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../../utils'
import QrReader from 'react-qr-reader'

const convertString = (value: string) => {
  return value
    .replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    .toUpperCase()
}

const AddDevice: React.FC = () => {
  const { t } = useTranslation()
  const navigator = useNavigate()
  const [passcode, setPasscode] = useState('')
  const [messageApi, contextHolder] = message.useMessage()
  const [isQRCode, setIsQRCode] = useState(false)
  const [isActive, setIsActive] = useState(false)

  const handleActivePassCode = async (passcode: string) => {
    setIsActive(true)
    messageApi.open({
      key: 'loading',
      type: 'loading',
      content: t('devices.add.message.loading'),
      duration: 0,
    })
    const activateDevice = httpsCallable(functions, 'ActivateDevice')
    const identifier = convertString(passcode.slice(0, 4))
    const secret = convertString(passcode.slice(4, 8))

    activateDevice({ identifier, secret })
      .then((result: any) => {
        const { deviceId } = result.data
        navigator(`/devices/edit/${deviceId}`)
      })
      .catch((error) => {
        messageApi.open({
          key: 'loading',
          type: 'error',
          content: error.message,
          duration: 3,
        })
      })
      .finally(() => setIsActive(false))
  }

  const handleScan = (data: any) => {
    if (data && !isActive) {
      handleActivePassCode(data.replace('-', ''))
    }
  }

  return (
    <>
      {contextHolder}
      <Modal
        title={t('devices.add-device')}
        centered
        open={true}
        onOk={() => handleActivePassCode(passcode)}
        onCancel={() => {
          !isActive && navigator('/devices')
        }}
        okButtonProps={{ disabled: passcode.length !== 8 || isActive }}
        cancelButtonProps={{ disabled: isActive }}
        bodyStyle={{ padding: '20px 0' }}
        width={400}
      >
        {isQRCode ? (
          <>
            <QrReader delay={500} onError={() => {}} onScan={handleScan} />
            <Button type="link" style={{ marginTop: '16px' }} block onClick={() => setIsQRCode(false)}>
              {t('devices.add.button.input-keyboard')}
            </Button>
          </>
        ) : (
          <>
            <Input
              placeholder={t('devices.input-passcode') || ''}
              maxLength={8}
              onChange={(event) => setPasscode(event.target.value)}
            />
            <Button type="link" style={{ marginTop: '16px' }} block onClick={() => setIsQRCode(true)}>
              {t('devices.add.button.input-qr-code')}
            </Button>
          </>
        )}
      </Modal>
    </>
  )
}

export default AddDevice
