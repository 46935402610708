import React, { useEffect, useState } from 'react'
import { Form, InputNumber, Modal, Switch, Typography, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore'
import { useRecoilValue } from 'recoil'
import { currentOrganizationRecoil } from '../../recoils/organization'
import { CALL_BUTTON_STATUS, Device, LIKE_BUTTON_STATUS } from '../../entities/Device'

const BatchSettings: React.FC<{ selectedDevicesId: string[] }> = ({ selectedDevicesId }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const db = getFirestore()
  const { organizationId } = useRecoilValue(currentOrganizationRecoil)

  const [messageApi, contextHolder] = message.useMessage()

  const [callButtonStatus, setCallButtonStatus] = useState(CALL_BUTTON_STATUS.SHOW)
  const [displayPostNumber, setDisplayPostNumber] = useState(0)
  const [delayMillisecond, setDelayMillisecond] = useState(0)
  const [postDescriptionTimeout, setPostDescriptionTimeout] = useState(0)
  const [likeButtonStatus, setLikeButtonStatus] = useState(LIKE_BUTTON_STATUS.SHOW)

  useEffect(() => {
    if (!selectedDevicesId.length) {
      navigate('/devices')
    }
  }, [navigate, selectedDevicesId.length])

  const handleCancel = () => {
    navigate('/devices')
  }

  const handleBatchSetting = () => {
    messageApi.open({
      key: 'message-batch-settings',
      type: 'loading',
      content: t('batch-settings.message.loading'),
      duration: 0,
    })

    Promise.all(
      selectedDevicesId.map(async (deviceId) => {
        const deviceRef = `/organizations/${organizationId}/devices/${deviceId}`
        const deviceSnapshot = await getDoc(doc(db, deviceRef))
        const device = deviceSnapshot.data() as Device
        return updateDoc(doc(db, deviceRef), {
          client: {
            current: {
              ...device.client.current,
              callButtonStatus,
              displayPostNumber,
              delayMillisecond,
              postDescriptionTimeout,
              likeButtonStatus,
            },
          },
        })
      })
    )
      .then(() => {
        messageApi.open({
          key: 'message-batch-settings',
          type: 'success',
          content: t('batch-settings.message.success'),
          duration: 3,
          onClose: () => navigate('/devices'),
        })
      })
      .catch((error) => {
        messageApi.open({
          key: 'message-batch-settings',
          type: 'error',
          content: error.message,
          duration: 3,
          onClose: () => navigate('/devices'),
        })
        throw new Error(error.message)
      })
  }

  return (
    <Modal title={t('batch-settings.title')} centered open={true} onOk={handleBatchSetting} onCancel={handleCancel}>
      <Form>
        <Typography.Title level={4}>{t('batch-settings.title.call')}</Typography.Title>
        <Form.Item label={t('device.call-function')}>
          <Switch
            checked={callButtonStatus === CALL_BUTTON_STATUS.SHOW}
            onChange={(value) => setCallButtonStatus(value ? CALL_BUTTON_STATUS.SHOW : CALL_BUTTON_STATUS.HIDE)}
          />
        </Form.Item>
        <Typography.Title level={4}>{t('batch-settings.title.post')}</Typography.Title>
        <Form.Item label={t('device.number-posts')}>
          <InputNumber
            min={0}
            max={100}
            value={displayPostNumber}
            onChange={(value) => setDisplayPostNumber(value || 0)}
          />
        </Form.Item>
        <Form.Item label={t('device.display-time')}>
          <InputNumber
            min={0}
            max={100}
            value={delayMillisecond / 1000}
            onChange={(value) => setDelayMillisecond((value || 0) * 1000)}
          />
        </Form.Item>
        <Form.Item label={t('device.display-time.image-title')}>
          <InputNumber
            min={0}
            max={100}
            value={postDescriptionTimeout / 1000}
            onChange={(value) => setPostDescriptionTimeout((value || 0) * 1000)}
          />
        </Form.Item>
        <Form.Item label={t('device.reaction')}>
          <Switch
            checked={likeButtonStatus === LIKE_BUTTON_STATUS.SHOW}
            onChange={(value) => setLikeButtonStatus(value ? LIKE_BUTTON_STATUS.SHOW : LIKE_BUTTON_STATUS.HIDE)}
          />
        </Form.Item>
      </Form>
      {contextHolder}
    </Modal>
  )
}

export default BatchSettings
