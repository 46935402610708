import React, { Suspense } from 'react'
import { Button, Dropdown, MenuProps } from 'antd'
import { Header } from 'antd/es/layout/layout'
import { UserGroupIcon } from '@heroicons/react/24/outline'
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'
import Organizations from './Organizations'
import { getAuth, signOut } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Language from './Language'

type HeaderProps = {
  collapsed: boolean
  setCollapsed: (collapsed: boolean) => void
}

const HeaderComponent: React.FC<HeaderProps> = ({ collapsed, setCollapsed }) => {
  const { t } = useTranslation()
  const auth = getAuth()
  const navigate = useNavigate()
  const items: MenuProps['items'] = [
    {
      key: 'logout',
      label: (
        <div
          onClick={() => {
            signOut(auth)
              .then(() => navigate('/login'))
              .catch((error) => {
                throw new Error(error.message)
              })
          }}
        >
          {t('application.logout')}
        </div>
      ),
    },
  ]

  return (
    <Header
      style={{
        padding: '0 20px 0 0',
        background: '#fefefe',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'fixed',
        zIndex: 500,
        backgroundColor: '#ffffff',
        width: collapsed ? 'calc(100% - 80px)' : 'calc(100% - 200px)',
      }}
    >
      <Button
        type="text"
        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        onClick={() => setCollapsed(!collapsed)}
        style={{ fontSize: '16px', width: 64, height: 64 }}
      />
      <div style={{ height: '64px', display: 'flex', alignItems: 'center' }}>
        <Language />

        <UserGroupIcon style={{ width: '22px', margin: '0 20px' }} />

        <Suspense fallback={<div style={{ minWidth: '120px' }} />}>
          <Organizations />
        </Suspense>
        <Dropdown menu={{ items }} placement="bottomLeft" arrow>
          <div
            style={{
              width: '32px',
              height: '32px',
              backgroundImage: `url('${auth.currentUser?.photoURL}')`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              borderRadius: '100%',
              cursor: 'pointer',
            }}
          />
        </Dropdown>
      </div>
    </Header>
  )
}

export default HeaderComponent
