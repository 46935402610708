import React, { useState } from 'react'
import { Button, Form, Input, message } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { getAuth, signInWithEmailAndPassword } from '@firebase/auth'
import { useTranslation } from 'react-i18next'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../../utils'

const isValidEmail = (email: string) => {
  return /\S+@\S+\.\S+/.test(email)
}

const Login: React.FC = () => {
  const auth = getAuth()
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const handleLogin = () => {
    setLoading(true)
    const checkAccountLogin = httpsCallable(functions, 'Login')
    checkAccountLogin({ email, enterprise: true })
      .then(() => {
        signInWithEmailAndPassword(auth, email, password)
          .then(() => {
            window.location.href = window.location.origin
          })
          .catch(() => {
            message.error(t('login.message.error'), 5)
            setLoading(false)
          })
      })
      .catch(() => {
        message.error(t('login.message.restricted'), 5)
        setLoading(false)
      })
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#eeeeee',
      }}
    >
      <div style={{ width: '320px', height: '300px', backgroundColor: '#ffffff', padding: '10px 30px' }}>
        <h1 style={{ textAlign: 'center' }}>{t('login.header')}</h1>
        <Form name="normal_login" initialValues={{ remember: true }} onFinish={handleLogin}>
          <Form.Item name="email" rules={[{ required: true, message: 'Please input your Email!' }]}>
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder={t('login.email') || 'Email'}
              onChange={(event) => setEmail(event.target.value)}
            />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder={t('login.password') || 'Password'}
              autoComplete="on"
              onChange={(event) => setPassword(event.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              disabled={!isValidEmail(email) || password.length < 8}
              loading={loading}
            >
              {t('login.login-button')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default Login
