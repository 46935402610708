import React, { useState } from 'react'
import { Breadcrumb, Button, Col, DatePicker, Row, Table } from 'antd'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import { Content } from 'antd/es/layout/layout'
import { DownloadOutlined } from '@ant-design/icons'
import { CSVLink } from 'react-csv'
import { useRecoilValue } from 'recoil'
import { callInfoQuery } from '../../recoils/calls'
import { currentOrganizationRecoil } from '../../recoils/organization'
import { Timestamp } from 'firebase/firestore'
import { convertEndDate, convertStartDate, convertTimestampToDate } from '../../utils'
import Title from 'antd/es/typography/Title'
import { getAuth } from 'firebase/auth'

interface CallsType {
  key: string
  title: string
  callerName: string
  receiverName: string
  duration: number
  createdAt: string
  endedAt: string
}

const currentDate = new Date()

const Calls: React.FC = () => {
  const { t } = useTranslation()
  const { RangePicker } = DatePicker
  const auth = getAuth()
  const { organizationId } = useRecoilValue(currentOrganizationRecoil)
  const [startDate, setStartDate] = useState(Timestamp.fromDate(convertStartDate()))
  const [endDate, setEndDate] = useState(Timestamp.fromDate(convertEndDate()))

  const callsInfo = useRecoilValue(
    callInfoQuery({ organizationId: auth.currentUser?.uid === organizationId && organizationId, startDate, endDate })
  )

  const breadcrumbCalls = [
    {
      title: t('breadcrumb.call-history'),
    },
  ]

  const columns: ColumnsType<CallsType> = [
    {
      title: t('call-history.table-header.title'),
      dataIndex: 'title',
    },
    {
      title: t('call-history.table-header.caller-name'),
      dataIndex: 'callerName',
    },
    {
      title: t('call-history.table-header.receiver-name'),
      dataIndex: 'receiverName',
    },
    {
      title: t('call-history.table-header.call-duration'),
      dataIndex: 'duration',
    },
    {
      title: t('call-history.table-header.created-at'),
      dataIndex: 'createdAt',
    },
    {
      title: t('call-history.table-header.ended-at'),
      dataIndex: 'endedAt',
    },
  ]

  const headers = [
    {
      label: t('call-history.table-header.title'),
      key: 'title',
    },
    {
      label: t('call-history.table-header.caller-name'),
      key: 'callerName',
    },
    {
      label: t('call-history.table-header.receiver-name'),
      key: 'receiverName',
    },
    {
      label: t('call-history.table-header.call-duration'),
      key: 'duration',
    },
    {
      label: t('call-history.table-header.created-at'),
      key: 'createdAt',
    },
    {
      label: t('call-history.table-header.ended-at'),
      key: 'endedAt',
    },
  ]

  const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
    if (dates) {
      setStartDate(Timestamp.fromDate(convertStartDate(dateStrings[0])))
      setEndDate(Timestamp.fromDate(convertEndDate(dateStrings[1])))
    } else {
      setStartDate(Timestamp.fromDate(convertStartDate()))
      setEndDate(Timestamp.fromDate(convertEndDate()))
    }
  }

  return (
    <>
      <Breadcrumb style={{ padding: '16px 20px 0' }} items={breadcrumbCalls} />
      <Content style={{ margin: '20px', minHeight: 280 }}>
        <Row style={{ padding: '20px', backgroundColor: '#fefefe', height: '100%' }}>
          <Col span={24}>
            <Title level={4} style={{ margin: 0 }}>
              {t('breadcrumb.call-history')}
            </Title>
          </Col>
          {auth.currentUser?.uid === organizationId ? (
            <>
              <Col span={24} style={{ margin: '16px 0', display: 'flex', justifyContent: 'space-between' }}>
                <RangePicker
                  onChange={onRangeChange}
                  allowClear={false}
                  defaultValue={[dayjs(currentDate.toLocaleDateString()), dayjs(currentDate.toLocaleDateString())]}
                />
                <Button icon={<DownloadOutlined />}>
                  <CSVLink
                    data={callsInfo}
                    headers={headers}
                    filename={`sugnee-call-history-${convertTimestampToDate(
                      startDate.seconds
                    )}-${convertTimestampToDate(endDate.seconds)}.csv`}
                  >
                    {t('call-history.button.export-csv')}
                  </CSVLink>
                </Button>
              </Col>
              <Col span={24}>
                <Table columns={columns} dataSource={callsInfo} />
              </Col>
            </>
          ) : (
            <Title level={5} type="danger">
              {t('call-history.message.permission')}
            </Title>
          )}
        </Row>
      </Content>
    </>
  )
}

export default Calls
