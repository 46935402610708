import { message } from 'antd'
import SpinLoading from '../loading'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useRef } from 'react'
import { getAuth, isSignInWithEmailLink } from 'firebase/auth'
import { signInWithEmailLink } from '@firebase/auth'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../../utils'

export const AcceptInvitation: React.FC = () => {
  const auth = getAuth()
  const navigate = useNavigate()

  const ref = useRef(false)

  useEffect(() => {
    if (!ref.current && isSignInWithEmailLink(auth, window.location.href)) {
      ref.current = true
      const url = new URL(window.location.href)
      const email = url.searchParams.get('email') as string
      const inviterId = url.searchParams.get('inviterId')
      const deviceId = url.searchParams.get('deviceId')
      const acceptInvitation = httpsCallable(functions, 'AcceptInvitation')
      signInWithEmailLink(auth, email, window.location.href)
        .then(() => {
          acceptInvitation({ inviterId, deviceId })
            .then(() => {
              auth.currentUser?.getIdToken(true).then(() => {
                navigate('/')
              })
            })
            .catch((error) => console.log(error))
        })
        .catch((error) => {
          message.error(error.message, 3)
          navigate('/login')
        })
    }
    // eslint-disable-next-line
  }, [])

  return <SpinLoading />
}
