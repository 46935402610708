import { UploadFile } from 'antd'
import { getFunctions } from 'firebase/functions'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'

export const functions = getFunctions(undefined, 'asia-northeast2')

export const convertTimestampToDateTime = (dateTimeNumber: number) => {
  if (!dateTimeNumber) return ''

  const dateTimeByLocal = new Date(dateTimeNumber * 1000)

  const year = dateTimeByLocal.getFullYear()
  const month = dateTimeByLocal.getMonth() < 9 ? `0${dateTimeByLocal.getMonth() + 1}` : dateTimeByLocal.getMonth() + 1
  const date = dateTimeByLocal.getDate() < 10 ? `0${dateTimeByLocal.getDate()}` : dateTimeByLocal.getDate()
  const hours = dateTimeByLocal.getHours() < 10 ? `0${dateTimeByLocal.getHours()}` : dateTimeByLocal.getHours()
  const minutes = dateTimeByLocal.getMinutes() < 10 ? `0${dateTimeByLocal.getMinutes()}` : dateTimeByLocal.getMinutes()

  return `${year}/${month}/${date} ${hours}:${minutes}`
}

export const convertTimestampToDate = (dateTimeNumber: number) => {
  if (!dateTimeNumber) return ''

  const dateTimeByLocal = new Date(dateTimeNumber * 1000)

  const year = dateTimeByLocal.getFullYear()
  const month = dateTimeByLocal.getMonth() < 9 ? `0${dateTimeByLocal.getMonth() + 1}` : dateTimeByLocal.getMonth() + 1
  const date = dateTimeByLocal.getDate() < 10 ? `0${dateTimeByLocal.getDate()}` : dateTimeByLocal.getDate()

  return `${year}/${month}/${date}`
}

export const convertStartDate = (date?: string) => {
  const dateLocal = date ? new Date(date) : new Date()
  const dateUTC = new Date(dateLocal.getUTCFullYear(), dateLocal.getUTCMonth(), dateLocal.getUTCDate(), 0, 0, 0)

  return dateUTC
}

export const convertEndDate = (date?: string) => {
  const dateLocal = date ? new Date(date) : new Date()
  const dateUTC = new Date(dateLocal.getUTCFullYear(), dateLocal.getUTCMonth(), dateLocal.getUTCDate(), 23, 59, 59)

  return dateUTC
}

export const DEFAULT_AVATAR =
  'https://firebasestorage.googleapis.com/v0/b/sugnee-dev-v2.appspot.com/o/images%2Favatars%2Fsugnee.png?alt=media&token=a71377c4-2592-4bd8-b669-54801e499a81'

type UploadParams = {
  uid: string
  url: string
}

export const upload = ({ uid, url }: UploadParams) => {
  if (url) {
    return [
      {
        uid,
        name: 'アバター',
        status: 'done',
        url,
      },
    ] as UploadFile[]
  }
  return []
}

type uploadParams = {
  newFile: UploadFile | undefined
  url: string
}

export const uploadStorage = async ({ newFile, url }: uploadParams) => {
  if (!newFile) return

  const storageRef = ref(getStorage(), url)
  const snapshot = await uploadBytes(storageRef, newFile as any)
  return snapshot.metadata.fullPath
}

export const getStorageUrl = async (path: string | undefined) => {
  if (!path) return DEFAULT_AVATAR

  const url = `${process.env.REACT_APP_BASE_URL_STORAGE}${path}`
  const downloadURLs = await getDownloadURL(ref(getStorage(), url))
  return downloadURLs
}
