import { Timestamp } from 'firebase/firestore'

export enum POST_TYPE {
  TEXT = 'application/text',
  IMAGE = 'application/jpeg',
}

type User = {
  id: string
  avatar: string
  displayName: string
}

type Post = {
  id: string
  content: string
  like: boolean
  likedAt?: Timestamp
  description?: string
  type: POST_TYPE
  createdByUser: User,
  deviceId: string
  likedByDevice?: string
  createdAt: Timestamp
  updatedAt: Timestamp
}

export default Post
