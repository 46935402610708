import React, { ReactNode, Suspense, useState } from 'react'
import { Breadcrumb, Button, Space } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { useParams } from 'react-router-dom'
import { CameraIcon as CameraOff, MicrophoneIcon as MicrophoneOff, XMarkIcon } from '@heroicons/react/24/outline'
import { CameraIcon as CameraOn, MicrophoneIcon as MicrophoneOn } from '@heroicons/react/24/solid'
import { useRecoilState } from 'recoil'
import { callRecoil } from '../../recoils/call'
import SpinLoading from '../loading'

type CallLayoutProps = {
  children: ReactNode
  room: boolean
  isGuestCall?: boolean
}

const CallLayout: React.FC<CallLayoutProps> = ({ room, children, isGuestCall = false }) => {
  const url = new URL(window.location.href)
  const params = useParams()
  const deviceId = isGuestCall ? (url.searchParams.get('deviceId') as string) : (params.deviceId as string)
  const breadcrumbCalls = [
    {
      title: 'Call',
    },
    {
      title: deviceId,
    },
  ]
  const [disableEndCall, setDisableEndCall] = useState(false)
  const [callSetting, setCallSetting] = useRecoilState(callRecoil)

  return (
    <>
      {!isGuestCall && <Breadcrumb items={breadcrumbCalls} />}

      <Content
        style={{
          marginTop: isGuestCall ? 0 : '20px',
          height: isGuestCall ? 'calc(100vh - 5px)' : 'calc(100vh - 150px)',
          backgroundColor: '#fefefe',
          position: 'relative',
        }}
      >
        <Suspense fallback={<SpinLoading />}>{children}</Suspense>
        <Space
          style={{
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            bottom: '23px',
            width: '100%',
            left: '0',
          }}
        >
          <Button
            shape="round"
            size="large"
            onClick={() => setCallSetting({ ...callSetting, muteCamera: !callSetting.muteCamera })}
          >
            {callSetting.muteCamera ? (
              <CameraOff style={{ width: '18px', marginTop: '4px' }} />
            ) : (
              <CameraOn style={{ width: '18px', marginTop: '4px' }} />
            )}
          </Button>
          <Button
            type="primary"
            danger
            shape="round"
            size="large"
            disabled={!room || disableEndCall}
            onClick={() => {
              setCallSetting({ ...callSetting, endCall: true })
              setDisableEndCall(true)
            }}
          >
            <XMarkIcon style={{ width: '18px', marginTop: '4px' }} />
          </Button>
          <Button
            shape="round"
            size="large"
            onClick={() => setCallSetting({ ...callSetting, muteMicrophone: !callSetting.muteMicrophone })}
          >
            {callSetting.muteMicrophone ? (
              <MicrophoneOff style={{ width: '18px', marginTop: '4px' }} />
            ) : (
              <MicrophoneOn style={{ width: '18px', marginTop: '4px' }} />
            )}
          </Button>
        </Space>
      </Content>
    </>
  )
}

export default CallLayout
