import React, { useState } from 'react'
import { Layout, Menu } from 'antd'
import Sider from 'antd/es/layout/Sider'
import type { MenuProps } from 'antd'
import { ChartBarIcon, DeviceTabletIcon, PhoneIcon, Cog8ToothIcon } from '@heroicons/react/24/outline'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import HeaderComponent from './header'
import { useTranslation } from 'react-i18next'

const LayoutComponent: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const itemsMenu: MenuProps['items'] = [
    {
      key: '',
      icon: <ChartBarIcon style={{ width: '16px' }} />,
      label: t('menu.item.dashboard'),
    },
    {
      key: 'devices',
      icon: <DeviceTabletIcon style={{ width: '16px' }} />,
      label: t('menu.item.devices'),
    },
    {
      key: 'calls',
      icon: <PhoneIcon style={{ width: '16px' }} />,
      label: t('menu.item.call-history'),
    },
    {
      key: 'settings',
      icon: <Cog8ToothIcon style={{ width: '16px' }} />,
      label: t('menu.item.settings'),
    },
  ]

  const [collapsed, setCollapsed] = useState(false)
  const location = useLocation()

  return (
    <Layout hasSider style={{ height: '100vh' }}>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        theme="light"
        style={{ height: '100vh', position: 'fixed', left: 0, top: 0 }}
      >
        <div
          style={{
            width: '100%',
            height: '65px',
            border: '1px solid #eeeeee',
            borderTop: 'none',
            display: 'flex',
            justifyContent: 'center',
          }}
        />

        <Menu
          defaultSelectedKeys={['']}
          selectedKeys={[location.pathname.split('/')[1]]}
          items={itemsMenu}
          onClick={({ key }) => navigate(key)}
        />
      </Sider>
      <Layout className="site-layout" style={{ marginLeft: collapsed ? 80 : 200, transition: 'all .3s' }}>
        <HeaderComponent collapsed={collapsed} setCollapsed={setCollapsed} />
        <div style={{ marginTop: '64px', backgroundColor: '#F5F5F5', padding: '20px' }}>
          <Outlet />
        </div>
      </Layout>
    </Layout>
  )
}

export default LayoutComponent
