import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Input, Modal, message } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { User, getAuth, updatePassword } from 'firebase/auth'
import { useTranslation } from 'react-i18next'

const SettingPassword: React.FC = () => {
  const auth = getAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const user = auth.currentUser as User
  const [isLoading, setIsLoading] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const handleResetPassword = async () => {
    setIsLoading(true)
    await updatePassword(user, newPassword)
      .then(() => {
        message.success(t('setting.account.password.message.success'), 3, () => navigate('/settings'))
      })
      .catch((error) => {
        message.error(error.message, 3, () => navigate('/settings'))
        throw new Error(error.message)
      })
  }

  return (
    <Modal
      title={t('setting.account.password.title')}
      centered
      open={true}
      okText={t('setting.account.password.button.ok')}
      onOk={handleResetPassword}
      okType="primary"
      okButtonProps={{
        danger: true,
        loading: isLoading,
        disabled: newPassword.length < 8 || confirmPassword.length < 8 || newPassword !== confirmPassword,
      }}
      cancelButtonProps={{ disabled: isLoading }}
      cancelText={t('setting.account.password.button.cancel')}
      onCancel={() => navigate('/settings')}
    >
      <Form labelCol={{ span: 7 }} style={{ paddingTop: '12px' }}>
        <Form.Item label={t('setting.account.password.new-password')}>
          <Input.Password
            placeholder={t('setting.account.password.new-password.placeholder') || 'Input new password'}
            value={newPassword}
            onChange={(event) => setNewPassword(event.target.value)}
            autoComplete="off"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
        <Form.Item label={t('setting.account.password.confirm-password')}>
          <Input.Password
            placeholder={t('setting.account.password.confirm-password.placeholder') || 'Input confirm password'}
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
            autoComplete="off"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default SettingPassword
