import { Button, Col, Form, Input, Modal, Row, Tooltip, UploadFile, UploadProps, message } from 'antd'
import Title from 'antd/es/typography/Title'
import React, { useState } from 'react'
import UploadImage from '../../uploadImage'
import { LinkOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Device } from '../../../entities/Device'
import { functions, getStorageUrl, upload, uploadStorage } from '../../../utils'
import { httpsCallable } from 'firebase/functions'
import { Timestamp } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'

type DeviceInfoParams = {
  organizationId: string
  deviceId: string | undefined
  device: Device
  setDevice: (device: Device) => void
}

type GuestCallURL = {
  createdAt: Timestamp
  deviceId: string
  expiredAt: Timestamp
  isOpened: boolean
  link: string
  organizationId: string
}

const DeviceInfo: React.FC<DeviceInfoParams> = ({ organizationId, deviceId, device, setDevice }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [displayName, setDisplayName] = useState(device?.client?.current?.displayName)
  const [name, setName] = useState(device?.client?.current?.name)
  const [modal, contextHolderModal] = Modal.useModal()
  const [isLoadingPublishGuest, setIsLoadingPublishGuest] = useState(false)
  const [messageApi, contextHolderMessage] = message.useMessage()

  const [fileList, setFileList] = useState<UploadFile[]>(
    upload({ uid: device?.id, url: device?.client?.current?.avatar })
  )
  const [uploadError, setUploadError] = useState(false)

  const handleSaveDisplayName = () => {
    if (displayName !== device?.client?.current?.displayName) {
      Promise.all([
        setDevice({
          ...device,
          client: { ...device?.client, current: { ...device?.client?.current, displayName } },
        }),
      ])
        .then(() => {
          messageApi.open({
            key: 'save-display-name',
            type: 'success',
            content: t('device.message.success.display-name'),
          })
        })
        .catch(() => {
          messageApi.open({
            key: 'save-display-name',
            type: 'error',
            content: t('device.message.error.display-name'),
          })
        })
    }
  }

  const handleSaveName = () => {
    if (name !== device?.client?.current?.name) {
      Promise.all([
        setDevice({
          ...device,
          client: { ...device?.client, current: { ...device?.client?.current, name } },
        }),
      ])
        .then(() => {
          messageApi.open({
            key: 'save-name',
            type: 'success',
            content: t('device.message.success.name'),
          })
        })
        .catch(() => {
          messageApi.open({
            key: 'save-name',
            type: 'error',
            content: t('device.message.error.name'),
          })
        })
    }
  }

  const handleChangeImage: UploadProps['onChange'] = async ({ fileList: newFileList, file: newFile }) => {
    if (uploadError) return
    if (newFileList.length === 0) {
      setFileList(newFileList)
      return
    }

    messageApi.open({
      key: 'changeAvatar',
      type: 'loading',
      content: t('setting.profile.avatar.message.saving'),
      duration: 0,
    })

    Promise.all([uploadStorage({ newFile, url: `images/device-avatars/${newFile?.uid}` })]).then((path) => {
      Promise.all([getStorageUrl(path[0])]).then((downloadURLs) => {
        Promise.all([
          setDevice({
            ...device,
            client: {
              ...device?.client,
              current: { ...device?.client?.current, avatar: downloadURLs[0] },
            },
          }),
        ])
          .then(() => {
            setFileList(newFileList)
            messageApi.open({
              key: 'changeAvatar',
              type: 'success',
              content: t('setting.profile.avatar.message.success'),
              duration: 5,
            })
          })
          .catch((error) => {
            messageApi.open({
              key: 'changeAvatar',
              type: 'error',
              content: error.message,
              duration: 5,
            })
            throw new Error(error.message)
          })
      })
    })
  }

  const handlePublishGuestCall = () => {
    setIsLoadingPublishGuest(true)
    const generateCallURL = httpsCallable<{}, GuestCallURL>(functions, 'GenerateCallURL')

    generateCallURL({
      urlPath: `${window.location.origin}/guest/call`,
      organizationId,
      deviceId,
    })
      .then(({ data }) => {
        setIsLoadingPublishGuest(false)

        modal.confirm({
          title: t('device.guest-call-modal.title'),
          icon: <ExclamationCircleOutlined />,
          content: (
            <Tooltip placement="top" title={data.link} arrow={true}>
              <div
                style={{
                  width: '100%',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  cursor: 'pointer',
                }}
              >
                {data.link}
              </div>
            </Tooltip>
          ),
          centered: true,
          okText: t('device.guest-call-modal.button.ok'),
          cancelText: t('device.guest-call-modal.button.cancel'),
          onOk: () => {
            navigator.clipboard.writeText(data.link)
            message.success(t('device.message.copied'), 5)
          },
        })
      })
      .catch((error) => {
        throw new Error(error.message)
      })
  }

  return (
    <Row style={{ padding: '0 32px 40px', backgroundColor: '#fefefe' }}>
      {contextHolderModal}
      {contextHolderMessage}
      <Col span={12}>
        <Title level={4}>{t('device.title.device-information')}</Title>
      </Col>
      <Col span={12} style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
        <Button type="primary" icon={<LinkOutlined />} onClick={handlePublishGuestCall} loading={isLoadingPublishGuest}>
          {t('device.button.publish-guest-call')}
        </Button>
        <Button type="primary" style={{ marginLeft: '8px' }} onClick={() => navigate(`/call/${deviceId}`)}>
          {t('devices.call')}
        </Button>
      </Col>
      <Col span={24}>
        <Form style={{ maxWidth: 600, marginTop: '32px' }}>
          <Form.Item label={t('device.avatar')}>
            <UploadImage fileList={fileList} setUploadError={setUploadError} handleChangeImage={handleChangeImage} />
          </Form.Item>
          <Form.Item label={t('device.display-name')}>
            <Input
              placeholder={t('device.display-name') || 'Display name'}
              value={displayName}
              onChange={(event) => setDisplayName(event.target.value)}
              onBlur={handleSaveDisplayName}
            />
          </Form.Item>
          <Form.Item label={t('device.device-name')}>
            <Input
              placeholder={t('device.device-name') || 'Device name'}
              value={name}
              onChange={(event) => setName(event.target.value)}
              onBlur={handleSaveName}
            />
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}

export default DeviceInfo
