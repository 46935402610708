import React, { useState } from 'react'
import { Breadcrumb, Button, Col, Row, Space, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useRecoilValue } from 'recoil'
import { devicesManagementRecoil } from '../../recoils/devices'
import { currentOrganizationRecoil } from '../../recoils/organization'
import { UserIcon } from '@heroicons/react/24/outline'
import { CALL_BUTTON_STATUS } from '../../entities/Device'
import { Outlet, useNavigate } from 'react-router-dom'
import { Content } from 'antd/es/layout/layout'
import { useTranslation } from 'react-i18next'
import { CSVLink } from 'react-csv'
import BatchPost from '../posts/batch'
import BatchSettings from '../settings/batch'
import Title from 'antd/es/typography/Title'
import { getAuth } from 'firebase/auth'

interface DataType {
  key: string
  displayName: string
  name: string
  callFeature: any
  contactList: any
  action: any
}

type DevicesProps = {
  isBatchPost?: boolean
  isBatchSettings?: boolean
}

const Devices: React.FC<DevicesProps> = ({ isBatchPost = false, isBatchSettings = false }) => {
  const { t } = useTranslation()
  const auth = getAuth()
  const navigate = useNavigate()

  const breadcrumbDevices = [
    {
      title: t('breadcrumb.devices'),
    },
  ]
  const [selectedDevicesId, setSelectedDevicesId] = useState<string[]>([])

  const columns: ColumnsType<DataType> = [
    {
      title: t('devices.table-header.display-name'),
      dataIndex: 'displayName',
    },
    {
      title: t('devices.table-header.OS'),
      dataIndex: 'OS',
    },
    {
      title: t('devices.table-header.name'),
      dataIndex: 'name',
    },
    {
      title: t('devices.table-header.call-feature'),
      dataIndex: 'callFeature',
    },
    {
      title: t('devices.table-header.contact-list'),
      dataIndex: 'contactList',
    },
    {
      title: t('devices.table-header.action'),
      dataIndex: 'action',
    },
  ]

  const headersExport = [
    {
      label: t('devices.table-header.display-name'),
      key: 'displayName',
    },
    {
      label: t('devices.table-header.OS'),
      key: 'OS',
    },
    {
      label: t('devices.table-header.name'),
      key: 'name',
    },
    {
      label: t('devices.table-header.call-feature'),
      key: 'callFeature',
    },
    {
      label: t('devices.table-header.contact-list'),
      key: 'contactList',
    },
  ]

  const { organizationId } = useRecoilValue(currentOrganizationRecoil)

  const devicesManagement = useRecoilValue(devicesManagementRecoil(organizationId))

  const devicesData = devicesManagement.map(({ data }) => ({
    key: data.id,
    displayName: data.displayName,
    OS: data.OS,
    name: data.name,
    callFeature: (
      <Space align="center">
        <div
          style={{
            width: '6px',
            height: '6px',
            borderRadius: '100%',
            backgroundColor: data.callButtonStatus === CALL_BUTTON_STATUS.HIDE ? '#FF4D4F' : '#7CB305',
          }}
        />
        {data.callButtonStatus === CALL_BUTTON_STATUS.HIDE ? 'off' : 'on'}
      </Space>
    ),
    contactList: (
      <Space align="center">
        {data.contacts.length} <UserIcon style={{ width: '16px', paddingTop: '5px' }} />
      </Space>
    ),
    action: (
      <Button type="link" block onClick={() => navigate(data.id)}>
        {t('devices.action.detail')}
      </Button>
    ),
  }))

  const dataExport = devicesManagement.map(({ data }) => ({
    displayName: data.displayName,
    OS: data.OS,
    name: data.name,
    callFeature: data.callButtonStatus === CALL_BUTTON_STATUS.HIDE ? 'off' : 'on',
    contactList: data.contacts.length,
  }))

  const changeSelectionRow = (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
    setSelectedDevicesId(selectedRowKeys as string[])
  }

  return (
    <>
      <Breadcrumb items={breadcrumbDevices} />
      <Content
        style={{ marginTop: '20px', minHeight: 280, padding: '20px', backgroundColor: '#fefefe', height: '100%' }}
      >
        <Row align="middle" style={{ height: '32px' }}>
          <Col span={12}>
            <Title level={4} style={{ margin: 0 }}>
              {t('devices.title')}
            </Title>
          </Col>
          {organizationId === auth.currentUser?.uid && (
            <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>
              <Button>
                <CSVLink data={dataExport} headers={headersExport} filename="sugnee-devices.csv">
                  {t('devices.export-csv')}
                </CSVLink>
              </Button>

              <Button type="primary" style={{ marginLeft: '8px' }} onClick={() => navigate('/devices/add')}>
                {t('devices.add')}
              </Button>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={24} style={{ display: 'flex', justifyContent: 'end', marginTop: '32px' }}>
            <Button
              type="primary"
              disabled={!selectedDevicesId.length}
              onClick={() => navigate('/devices/settings/batch')}
            >
              {t('devices.button.batch-settings')}
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: '8px' }}
              disabled={!selectedDevicesId.length}
              onClick={() => navigate('/devices/posts/batch')}
            >
              {t('devices.button.batch-post')}
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: '16px' }}>
          <Col span={24}>
            <Table
              rowSelection={{ type: 'checkbox', onChange: changeSelectionRow }}
              columns={columns}
              dataSource={devicesData}
            />
          </Col>
        </Row>
      </Content>
      {isBatchPost && <BatchPost selectedDevicesId={selectedDevicesId} />}
      {isBatchSettings && <BatchSettings selectedDevicesId={selectedDevicesId} />}
      <Outlet />
    </>
  )
}

export default Devices
