import React from 'react'
import { Button, Dropdown, Space } from 'antd'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { useRecoilState, useRecoilValue } from 'recoil'
import { organizationsRecoil } from '../../recoils/organizations'
import { currentOrganizationRecoil } from '../../recoils/organization'

const Organizations: React.FC = () => {
  const organizations = useRecoilValue(organizationsRecoil)
  const [organization, setOrganization] = useRecoilState(currentOrganizationRecoil)

  const itemsDropdown = organizations.map(({ id, displayName }) => ({
    key: `${id}-/-${displayName}`,
    label: displayName,
  }))
  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items: itemsDropdown,
        onClick: ({ key }) =>
          setOrganization({ organizationId: key.split('-/-')[0], organizationName: key.split('-/-')[1] }),
      }}
    >
      <Button type="link" style={{ fontSize: '16px', height: 44 }}>
        <Space style={{ minWidth: '100px', display: 'flex', justifyContent: 'space-between' }}>
          {organization.organizationName} <ChevronDownIcon style={{ width: '20px', marginTop: '6px' }} />
        </Space>
      </Button>
    </Dropdown>
  )
}

export default Organizations
