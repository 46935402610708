import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Upload, UploadFile, message } from 'antd'
import { RcFile, UploadProps } from 'antd/es/upload'
import UploadButton from './uploadButon'

type UploadImageProps = {
  fileList: UploadFile[]
  setUploadError: (isError: boolean) => void
  handleChangeImage: UploadProps['onChange']
}

const UploadImage: React.FC<UploadImageProps> = ({ fileList, setUploadError, handleChangeImage }) => {
  const { t } = useTranslation()
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error(t('upload-image.message.error.jpg-png'))
      setUploadError(true)
      return true
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error(t('upload-image.message.error.size'))
      setUploadError(true)
      return true
    }
    setUploadError(false)
    return false
  }

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (error) => reject(error)
    })

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
  }

  return (
    <>
      <Upload
        listType="picture-card"
        beforeUpload={beforeUpload}
        maxCount={1}
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChangeImage}
      >
        {fileList.length >= 1 ? null : <UploadButton />}
      </Upload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={() => setPreviewOpen(false)}>
        <img alt="preview" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  )
}

export default UploadImage
