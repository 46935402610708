import { Timestamp } from 'firebase/firestore'

export enum CALL_BUTTON_STATUS {
  SHOW = 'show',
  HIDE = 'hide',
}

export enum LIKE_BUTTON_STATUS {
  SHOW = 'show',
  HIDE = 'hide',
}

export enum OS {
  IOS = 'ios',
  ANDROID = 'android',
}

type Contacts = {
  id: string
  displayName: string
  avatar: string
}

type Client = {
  current: {
    OS: OS
    contacts: Contacts[]
    createdAt: Timestamp
    updatedAt: Timestamp
    avatar: string
    displayName: string
    name: string
    pushKitToken: string
    callButtonStatus: CALL_BUTTON_STATUS
    displayPostNumber: number
    delayMillisecond: number
    postDescriptionTimeout?: number
    likeButtonStatus?: LIKE_BUTTON_STATUS
  }
}

export type Device = {
  id: string
  client: Client
  managersId: string[]
  identity: string
  notificationToken: string
}

export type Contact = {
  id: string
  displayName: string
  avatar: string
  callStatus: boolean
}

export type DeviceManagement = {
  organization: string
  role: 'owner' | 'manager'
  data: {
    id: string
    avatar: string
    name: string
    OS: OS
    callButtonStatus: CALL_BUTTON_STATUS
    displayName: string
    contacts: Omit<Contact, 'callStatus'>[]
  }
}
