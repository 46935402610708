import { doc, getDoc, getFirestore, onSnapshot, updateDoc } from 'firebase/firestore'
import { atomFamily } from 'recoil'
import Post from '../entities/Post'

type PostParams = {
  organizationId: string
  deviceId: string | undefined
  postId: string | undefined
}

export const postRecoil = atomFamily<Post, PostParams>({
  key: 'post',
  default: async ({ organizationId, deviceId, postId }) => {
    const db = getFirestore()
    const postRef = doc(db, `/organizations/${organizationId}/devices/${deviceId || 'default'}/posts/${postId}`)
    const snapshot = await getDoc(postRef)

    return snapshot.data() as Post
  },
  effects: ({ organizationId, deviceId, postId }) => {
    const db = getFirestore()
    return [
      ({ setSelf }) => {
        const postRef = doc(db, `/organizations/${organizationId}/devices/${deviceId || 'default'}/posts/${postId}`)
        return onSnapshot(postRef, (snapshot) => {
          setSelf(snapshot.data() as Post)
        })
      },
      ({ onSet }) => {
        const postRef = doc(db, `/organizations/${organizationId}/devices/${deviceId}/posts/${postId}`)
        return onSet((newValue) => {
          if (newValue) {
            return updateDoc(postRef, newValue)
          }
        })
      },
    ]
  },
})
