import { doc, getDoc, getFirestore, onSnapshot, updateDoc } from 'firebase/firestore'
import { atom, atomFamily } from 'recoil'
import Organization from '../entities/Organization'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const organizationRecoil = atomFamily<Organization, string>({
  key: 'organization',
  default: async (organizationId) => {
    const db = getFirestore()
    const organizationRef = doc(db, `/organizations/${organizationId}`)
    const snapshot = await getDoc(organizationRef)

    return snapshot.data() as Organization
  },
  effects: (organizationId) => {
    const db = getFirestore()
    return [
      ({ setSelf }) => {
        const organizationRef = doc(db, `/organizations/${organizationId}`)
        return onSnapshot(organizationRef, (snapshot) => {
          setSelf(snapshot.data() as Organization)
        })
      },
      ({ onSet }) => {
        const organizationRef = doc(db, `/organizations/${organizationId}`)
        return onSet((newValue) => {
          if (newValue) {
            return updateDoc(organizationRef, newValue)
          }
        })
      },
    ]
  },
})

export const currentOrganizationRecoil = atom({
  key: 'currentOrganization',
  default: {
    organizationId: '',
    organizationName: '',
  },
  effects_UNSTABLE: [persistAtom],
})
