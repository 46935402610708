import { atom } from 'recoil'

export const callRecoil = atom({
  key: 'call',
  default: {
    muteCamera: false,
    muteMicrophone: false,
    endCall: false,
  },
})
