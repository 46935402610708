import React from 'react'
import { useTranslation } from 'react-i18next'
import { Cascader, Col, Form, Row, Switch, message } from 'antd'
import Title from 'antd/es/typography/Title'
import { CALL_BUTTON_STATUS, Device } from '../../../entities/Device'
import { useRecoilValue } from 'recoil'
import { devicesManagementRecoil } from '../../../recoils/devices'

type ContactListParams = {
  organizationId: string
  deviceId: string | undefined
  device: Device
  setDevice: (device: Device) => void
}

const ContactList: React.FC<ContactListParams> = ({ organizationId, deviceId, device, setDevice }) => {
  const { t } = useTranslation()
  const [messageApi, contextHolderMessage] = message.useMessage()
  const devicesManagement = useRecoilValue(devicesManagementRecoil(organizationId))

  const contactList = devicesManagement.filter((device) => device.data.id !== deviceId)

  const optionsCascader = (contactList || []).map(({ data }) => ({
    value: `${data.id}-/-${data?.avatar}-/-${data?.displayName}`,
    label: data?.displayName,
  }))

  const handleChangeCallButtonStatus = (value: boolean) => {
    const callButtonStatus = value ? CALL_BUTTON_STATUS.SHOW : CALL_BUTTON_STATUS.HIDE
    Promise.all([
      setDevice({
        ...device,
        client: { ...device?.client, current: { ...device?.client?.current, callButtonStatus } },
      }),
    ])
      .then(() => {
        messageApi.open({
          key: 'change-button-status',
          type: 'success',
          content: t('device.message.success.call-function'),
        })
      })
      .catch(() => {
        messageApi.open({
          key: 'change-button-status',
          type: 'error',
          content: t('device.message.error.call-function'),
        })
      })
  }

  const handleChangeContacts = (value: any) => {
    const contacts = value.map((item: any) => ({
      id: item.toString().split('-/-')[0],
      avatar: item.toString().split('-/-')[1],
      displayName: item.toString().split('-/-')[2],
    }))

    Promise.all([
      setDevice({
        ...device,
        client: { ...device?.client, current: { ...device?.client?.current, contacts } },
      }),
    ])
      .then(() => {
        messageApi.open({
          key: 'change-contacts',
          type: 'success',
          content: t('device.message.success.contacts'),
        })
      })
      .catch(() => {
        messageApi.open({
          key: 'change-contacts',
          type: 'error',
          content: t('device.message.error.contacts'),
        })
      })
  }

  return (
    <Row style={{ padding: '0 32px 40px', backgroundColor: '#fefefe', marginTop: '32px' }}>
      {contextHolderMessage}
      <Col span={24}>
        <Title level={4}>{t('device.title.contact-list')}</Title>
        <Form style={{ maxWidth: 600, marginTop: '32px' }}>
          <Form.Item label={t('device.call-function')}>
            <Switch
              defaultChecked
              checked={device?.client?.current?.callButtonStatus === CALL_BUTTON_STATUS.SHOW}
              onChange={(value) => handleChangeCallButtonStatus(value)}
            />
          </Form.Item>
          <Form.Item label={t('device.members')}>
            <Cascader
              options={optionsCascader}
              placeholder={t('device.members.placeholder')}
              showCheckedStrategy={Cascader.SHOW_CHILD}
              multiple
              defaultValue={device?.client?.current?.contacts.map((contact) => [
                `${contact.id}-/-${contact.avatar}-/-${contact.displayName}`,
              ])}
              onChange={handleChangeContacts}
            />
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}

export default ContactList
