import React, { useEffect, useState } from 'react'
import { User, getAuth, isSignInWithEmailLink, onAuthStateChanged } from 'firebase/auth'
import { Outlet, useNavigate } from 'react-router-dom'
import { getOrganizationsApi, organizationsRecoil } from '../recoils/organizations'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { currentOrganizationRecoil } from '../recoils/organization'

const AuthProvider: React.FC = () => {
  const auth = getAuth()
  const navigate = useNavigate()
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const setOrganizations = useSetRecoilState(organizationsRecoil)
  const [organization, setOrganization] = useRecoilState(currentOrganizationRecoil)

  // If logged out we will redirect to login
  useEffect(() => {
    if (!isSignInWithEmailLink(auth, window.location.href)) {
      onAuthStateChanged(auth, (user) => {
        setCurrentUser(user)
        if (!user) {
          setOrganization({
            organizationId: '',
            organizationName: '',
          })
          navigate('/login')
        } else {
          const setNewOrganizations = async () => {
            const newOrganizations = await getOrganizationsApi()
            setOrganizations(newOrganizations)
            if (!organization.organizationId) {
              setOrganization({
                organizationId: newOrganizations[0].id,
                organizationName: newOrganizations[0].displayName,
              })
            }
          }
          setNewOrganizations()
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!currentUser) {
    return <></>
  }

  return <Outlet />
}

export default AuthProvider
