import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { message, Modal } from 'antd'
import { collection, doc, getDocs, getFirestore, query, writeBatch } from 'firebase/firestore'
import { useRecoilValue } from 'recoil'
import { currentOrganizationRecoil } from '../../recoils/organization'

const DeleteDevice = () => {
  const db = getFirestore()
  const batch = writeBatch(db)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { organizationId } = useRecoilValue(currentOrganizationRecoil)
  const { deviceId } = useParams()
  const [isLoading, setIsLoading] = useState(false)

  const handleDeleteDevice = () => {
    if (!organizationId || !deviceId) {
      return
    }

    const deleteDeviceData = async () => {
      setIsLoading(true)
      const deviceRef = doc(db, `organizations/${organizationId}/devices/${deviceId}`)
      const posts = await getDocs(query(collection(db, `organizations/${organizationId}/devices/${deviceId}/posts`)))
      posts.docs.forEach((doc) => {
        batch.delete(doc.ref)
      })
      batch.delete(deviceRef)
      await batch.commit()
    }

    deleteDeviceData()
      .then(() => {
        message.success(t('other.delete.success'), 4)
        navigate(`/devices`)
      })
      .catch(() => {
        message.error(t('other.delete.error'), 4)
        navigate(`/devices/${deviceId}`)
      })
  }

  return (
    <Modal
      title={t('other.dialog.title')}
      centered
      open={true}
      okText={t('other.dialog.yes')}
      onOk={handleDeleteDevice}
      okType="primary"
      okButtonProps={{ danger: true, loading: isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
      cancelText={t('other.dialog.no')}
      onCancel={() => navigate(`/devices/${deviceId}`)}
    >
      {t('other.dialog.content')}
    </Modal>
  )
}

export default DeleteDevice
