import { Button, Col, Row, Space } from 'antd'
import Title from 'antd/es/typography/Title'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const Other: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <Row style={{ padding: '0 32px 16px', backgroundColor: '#fefefe', marginTop: '32px' }}>
      <Col span={24}>
        <Space style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          <Space style={{ width: '100%' }}>
            <Title level={4}>{t('other.title')}</Title>
          </Space>
          <Space>
            <Button type="primary" danger onClick={() => navigate('delete')}>
              {t('other.button.delete')}
            </Button>
          </Space>
        </Space>
      </Col>
    </Row>
  )
}

export default Other
