import { Space, Spin } from 'antd'
import React from 'react'

const SpinLoading: React.FC = () => {
  return (
    <Space
      direction="vertical"
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: 'calc(100vh - 64px)',
      }}
    >
      <Spin tip="Loading ..." size="large" style={{ alignContent: 'center' }}>
        <div className="content" />
      </Spin>
    </Space>
  )
}

export default SpinLoading
