import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Input, Modal, message } from 'antd'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../../utils'
import { User, getAuth } from 'firebase/auth'
import { useTranslation } from 'react-i18next'

const SettingEmail: React.FC = () => {
  const auth = getAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { email } = auth.currentUser as User
  const [isLoading, setIsLoading] = useState(false)
  const [newEmail, setNewEmail] = useState('')

  const handleSendResetMail = () => {
    setIsLoading(true)
    const sendConfirmChangeEmail = httpsCallable(functions, 'SendConfirmChangeEmail')
    const url = `${window?.location.origin}/change-email/callback?email=${email}&newEmail=${newEmail}`

    sendConfirmChangeEmail({ continueURL: url, email, newEmail })
      .then(() => {
        message.success(t('setting.account.email.message.send.success'), 3, () => navigate('/settings'))
      })
      .catch((error) => {
        message.error(error.message, 3, () => navigate('/settings'))
      })
  }

  return (
    <Modal
      title={t('setting.account.email.title')}
      centered
      open={true}
      okText={t('setting.account.email.button.ok')}
      onOk={handleSendResetMail}
      okType="primary"
      okButtonProps={{ danger: true, loading: isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
      cancelText={t('setting.account.email.button.cancel')}
      onCancel={() => navigate('/settings')}
    >
      <Form>
        <Form.Item label={t('setting.account.email.new-email')} style={{ padding: '12px 0' }}>
          <Input
            type="email"
            placeholder={t('setting.account.email.new-email.placeholder') || 'Input new email'}
            value={newEmail}
            onChange={(event) => setNewEmail(event.target.value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default SettingEmail
