import { atomFamily, selectorFamily, waitForAll } from 'recoil'
import Post from '../entities/Post'
import { deviceRecoil } from './device'
import { collection, getDocs, getFirestore, onSnapshot, orderBy, query } from 'firebase/firestore'

type PostParams = {
  organizationId: string
  deviceId: string | undefined
}

export const postsRecoil = atomFamily<Post[], PostParams>({
  key: 'posts',
  default: async ({ organizationId, deviceId }) => {
    if (!organizationId || !deviceId) {
      return []
    }
    const db = getFirestore()
    const postsQuery = query(
      collection(db, `/organizations/${organizationId}/devices/${deviceId}/posts`),
      orderBy('createdAt', 'desc')
    )

    const postsSnapshot = await getDocs(postsQuery)

    const posts = postsSnapshot.docs.map((post) => ({
      ...post.data(),
      id: post.id,
      deviceId: deviceId,
    }))

    return posts as Post[]
  },
  effects: ({ organizationId, deviceId }) => {
    const db = getFirestore()

    return [
      ({ setSelf }) => {
        if (!organizationId || !deviceId) {
          return setSelf([])
        }
        const postsQuery = query(
          collection(db, `/organizations/${organizationId}/devices/${deviceId}/posts`),
          orderBy('createdAt', 'desc')
        )

        return onSnapshot(postsQuery, (snapshot) => {
          setSelf(
            snapshot.docs.map((post) => ({
              ...post.data(),
              id: post.id,
              deviceId: deviceId,
            })) as Post[]
          )
        })
      },
    ]
  },
})

type RecentPostParams = {
  organizationId: string
  deviceIds: string[]
}

export const recentLikedPostsRecoil = selectorFamily<Post[], RecentPostParams>({
  key: 'recentLikedPostsRecoil',
  get:
    ({ organizationId, deviceIds }) =>
    ({ get }) => {
      const postsByDevice = get(
        waitForAll(deviceIds.map((deviceId) => postsRecoil({ organizationId, deviceId })))
      )

      const postsByOrganization = postsByDevice.flatMap(posts => posts);

      const devices = get(
        waitForAll(deviceIds.map((deviceId) => deviceRecoil({ organizationId, deviceId })))
      );

      const likedPosts = postsByOrganization.filter((post) => post.like === true)

      const postsWithDeviceInfo = likedPosts.map((post) => {
        const deviceIndex = deviceIds.findIndex((deviceId) => deviceId === post.deviceId)
        const device = devices[deviceIndex]
        return {
          ...post,
          likedByDevice: device?.client?.current?.displayName,
        }
      })
      
      const postsSorted = postsWithDeviceInfo.sort((a, b) => (b.likedAt?.seconds || 0) - (a.likedAt?.seconds || 0))

      if (postsSorted.length < 11) {
        return postsSorted as Post[]
      }

    return postsSorted.slice(0, 10) as Post[]
  },
})
