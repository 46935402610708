import React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const UploadButton: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{t('upload-button.upload')}</div>
    </div>
  )
}

export default UploadButton
