import React, { useState, useEffect } from 'react'
import { Button, Drawer, Tabs, UploadFile, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { currentOrganizationRecoil } from '../../recoils/organization'
import { useTranslation } from 'react-i18next'
import { POST_TYPE } from '../../entities/Post'
import { Timestamp, addDoc, collection, getFirestore } from 'firebase/firestore'
import { User, getAuth } from 'firebase/auth'
import PostMessage from './PostMessage'
import PostImage from './PostImage'
import { getStorageUrl, uploadStorage } from '../../utils'

const BatchPost: React.FC<{ selectedDevicesId: string[] }> = ({ selectedDevicesId }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const auth = getAuth()
  const db = getFirestore()
  const { uid, photoURL, displayName } = auth.currentUser as User
  const { organizationId } = useRecoilValue(currentOrganizationRecoil)
  const [posted, setPosted] = useState(false)
  const [uploadError, setUploadError] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()

  const [postType, setPostType] = useState(POST_TYPE.TEXT)
  const [postImage, setPostImage] = useState<any>()
  const [postMessage, setPostMessage] = useState('')
  const [postDescription, setPostDescription] = useState('')

  const [fileList, setFileList] = useState<UploadFile[]>([])

  const changeTab = (key: string) => {
    if (posted) return
    setPostType(key as POST_TYPE)
  }

  const onCloseDrawer = () => {
    navigate('/devices')
  }

  const handleBatchPost = async () => {
    if (uploadError) {
      return
    }
    setPosted(true)
    messageApi.open({
      key: 'message-batch',
      type: 'loading',
      content: t('post.message.loading'),
      duration: 0,
    })
    const getPath = async () => {
      if (postType === POST_TYPE.TEXT) {
        return
      }

      return uploadStorage({ newFile: postImage, url: `images/post/${fileList[0]?.uid}` })
    }

    await Promise.all([getPath()]).then((path) => {
      const getContent = async () => {
        if (postType === POST_TYPE.TEXT) {
          return postMessage
        }

        return getStorageUrl(path[0])
      }
      Promise.all([getContent()]).then((contents) => {
        const post = {
          content: contents[0],
          type: postType,
          description: postType === POST_TYPE.IMAGE ? postDescription : '',
          createdByUser: {
            id: uid,
            avatar: photoURL,
            displayName: displayName,
          },
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
        }

        Promise.all(
          selectedDevicesId.map((deviceId) => {
            return addDoc(collection(db, `/organizations/${organizationId}/devices/${deviceId}/posts`), post)
          })
        )
          .then(() => {
            messageApi.open({
              key: 'message-batch',
              type: 'success',
              content: t('post.message.success'),
              duration: 3,
              onClose: () => navigate('/devices'),
            })
          })
          .catch((error) => {
            messageApi.open({
              key: 'message-batch',
              type: 'error',
              content: error.message,
              duration: 3,
              onClose: () => navigate('/devices'),
            })
            throw new Error(error.message)
          })
      })
    })
  }

  useEffect(() => {
    if (!selectedDevicesId.length) {
      navigate('/devices')
    }
  }, [navigate, selectedDevicesId.length])

  return (
    <>
      {contextHolder}
      <Drawer
        title={t('post.batch.title')}
        placement="bottom"
        onClose={onCloseDrawer}
        height="70vh"
        bodyStyle={{ padding: '0' }}
        closeIcon={
          <Button type="link" onClick={onCloseDrawer} disabled={posted}>
            {t('post.button.cancel')}
          </Button>
        }
        headerStyle={{ textAlign: 'center' }}
        open={true}
        extra={
          <Button
            type="link"
            disabled={posted || (postType === POST_TYPE.IMAGE && uploadError)}
            onClick={handleBatchPost}
          >
            {t('post.button.save')}
          </Button>
        }
      >
        <Tabs
          onChange={changeTab}
          activeKey={postType}
          items={[
            {
              label: t('post.tab.message'),
              key: POST_TYPE.TEXT,
              children: (
                <PostMessage
                  organizationId={organizationId}
                  deviceIds={selectedDevicesId}
                  posted={posted}
                  postMessage={postMessage}
                  setPostMessage={setPostMessage}
                />
              ),
            },
            {
              label: t('post.tab.image'),
              key: POST_TYPE.IMAGE,
              children: (
                <PostImage
                  organizationId={organizationId}
                  deviceIds={selectedDevicesId}
                  postDescription={postDescription}
                  setPostDescription={setPostDescription}
                  setPostImage={setPostImage}
                  posted={posted}
                  fileList={fileList}
                  setFileList={setFileList}
                  setUploadError={setUploadError}
                />
              ),
            },
          ]}
        />
      </Drawer>
    </>
  )
}

export default BatchPost
