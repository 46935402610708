import React from 'react'
import { Button, Dropdown, MenuProps, Space } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { useRecoilState } from 'recoil'
import { languageCurrentRecoil } from '../../recoils/language'

const Language: React.FC = () => {
  const [languageCurrent, setLanguageCurrent] = useRecoilState<string>(languageCurrentRecoil)

  const items: MenuProps['items'] = [
    {
      key: 'ja',
      label: <div onClick={() => setLanguageCurrent('ja')}>JA</div>,
    },
    {
      key: 'en',
      label: <div onClick={() => setLanguageCurrent('en')}>EN</div>,
    },
  ]
  return (
    <Dropdown
      menu={{ items, defaultSelectedKeys: [languageCurrent], selectable: true }}
      placement="bottomLeft"
      arrow
      trigger={['click']}
    >
      <Button>
        <Space>
          {languageCurrent.toLocaleUpperCase()}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  )
}

export default Language
