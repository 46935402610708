import React, { Suspense } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css'
import './i18n/init'
import './firebase-config'
import { RecoilRoot } from 'recoil'
import LayoutComponent from './components/LayoutComponent'
import Devices from './components/devices'
import Dashboard from './components/dashboard'
import Calls from './components/calls'
import Settings from './components/settings'
import Login from './components/login'
import AuthProvider from './auth/AuthProvider'
import DeviceDetail from './components/devices/detail'
import SpinLoading from './components/loading'
import Call from './components/call'
import AddDevice from './components/devices/add'
import EditDevice from './components/devices/edit'
import EditPost from './components/posts/edit'
import DeletePost from './components/posts/delete'
import AddPost from './components/posts/add'
import AddManager from './components/managers/add'
import { AcceptInvitation } from './components/callBack/AcceptInvitation'
import DeleteManager from './components/managers/delete'
import DeleteDevice from './components/devices/delete'
import SettingEmail from './components/settings/email'
import SettingPassword from './components/settings/password'
import DeleteAccount from './components/settings/deleteAccount'
import Logout from './components/settings/logout'
import ChangeEmail from './components/callBack/ChangEmail'
import GuestCall from './components/call/GuestCall'
import I18nProvider from './i18n/i18nProvider'

const SugneeEnterPrise: React.FC = () => {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <Routes>
          <Route element={<I18nProvider />}>
            <Route
              path="guest/call"
              element={
                <Suspense fallback={<SpinLoading />}>
                  <GuestCall />
                </Suspense>
              }
            />
            <Route element={<AuthProvider />}>
              <Route element={<LayoutComponent />}>
                <Route
                  index
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <Dashboard />
                    </Suspense>
                  }
                />
                <Route
                  path="/"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <Dashboard />
                    </Suspense>
                  }
                />
                <Route
                  path="/devices"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <Devices />
                    </Suspense>
                  }
                >
                  <Route path="add" element={<AddDevice />} />
                  <Route path="edit/:deviceId" element={<EditDevice />} />
                </Route>
                <Route
                  path="/devices/posts/batch"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <Devices isBatchPost />
                    </Suspense>
                  }
                />
                <Route
                  path="/devices/settings/batch"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <Devices isBatchSettings />
                    </Suspense>
                  }
                />

                <Route
                  path="/devices/:deviceId"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <DeviceDetail />
                    </Suspense>
                  }
                >
                  <Route
                    path="delete"
                    element={
                      <Suspense fallback={<SpinLoading />}>
                        <DeleteDevice />
                      </Suspense>
                    }
                  />
                  <Route
                    path="manager/:managerId/delete"
                    element={
                      <Suspense fallback={<SpinLoading />}>
                        <DeleteManager />
                      </Suspense>
                    }
                  />
                  <Route
                    path="managers/add"
                    element={
                      <Suspense fallback={<SpinLoading />}>
                        <AddManager />
                      </Suspense>
                    }
                  />
                  <Route
                    path="posts/add"
                    element={
                      <Suspense fallback={<SpinLoading />}>
                        <AddPost />
                      </Suspense>
                    }
                  />
                  <Route
                    path="posts/edit/:postId"
                    element={
                      <Suspense fallback={<SpinLoading />}>
                        <EditPost />
                      </Suspense>
                    }
                  />
                  <Route
                    path="posts/delete/:postId"
                    element={
                      <Suspense fallback={<SpinLoading />}>
                        <DeletePost />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  path="/call/:deviceId"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <Call />
                    </Suspense>
                  }
                />
                <Route
                  path="/calls"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <Calls />
                    </Suspense>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <Settings />
                    </Suspense>
                  }
                >
                  <Route path="email" element={<SettingEmail />} />
                  <Route path="password" element={<SettingPassword />} />
                  <Route path="logout" element={<Logout />} />
                  <Route path="delete-account" element={<DeleteAccount />} />
                </Route>
              </Route>
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/accept-invitation" element={<AcceptInvitation />} />
            <Route path="/change-email/callback" element={<ChangeEmail />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
  )
}

export default SugneeEnterPrise
