import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Modal, message } from 'antd'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../../utils'
import { getAuth, signOut } from 'firebase/auth'
import { useTranslation } from 'react-i18next'

const DeleteAccount: React.FC = () => {
  const auth = getAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const handleDisableAccount = () => {
    setIsLoading(true)
    const disableAccount = httpsCallable(functions, 'DisableAccount')
    disableAccount()
      .then(() => {
        message.success(t('setting.account.delete.message.success'), 3, () => signOut(auth))
      })
      .catch((error) => {
        message.error(error.message, 3, () => navigate('/settings'))
        throw new Error(error.message)
      })
  }

  return (
    <Modal
      title={t('setting.account.delete.title')}
      centered
      open={true}
      okText={t('setting.account.delete.button.ok')}
      onOk={handleDisableAccount}
      okType="primary"
      okButtonProps={{ danger: true, loading: isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
      cancelText={t('setting.account.delete.button.cancel')}
      onCancel={() => navigate('/settings')}
    >
      {t('setting.account.delete.confirm-message')}
    </Modal>
  )
}

export default DeleteAccount
