import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Form, InputNumber, Switch, message } from 'antd'
import Title from 'antd/es/typography/Title'
import { Device, LIKE_BUTTON_STATUS } from '../../../entities/Device'

type PostParams = {
  device: Device
  setDevice: (device: Device) => void
}

const PostSetting: React.FC<PostParams> = ({ device, setDevice }) => {
  const { t } = useTranslation()
  const [messageApi, contextHolderMessage] = message.useMessage()

  const [displayPostNumber, setDisplayPostNumber] = useState(device?.client?.current?.displayPostNumber || 0)
  const [delayMillisecond, setDelayMillisecond] = useState(device?.client?.current?.delayMillisecond || 0)
  const [postDescriptionTimeout, setPostDescriptionTimeout] = useState(
    device?.client?.current?.postDescriptionTimeout || 0
  )
  const [likeButtonStatus, setLikeButtonStatus] = useState(
    device?.client?.current?.likeButtonStatus || LIKE_BUTTON_STATUS.HIDE
  )

  const handleSavePost = () => {
    Promise.all([
      setDevice({
        ...device,
        client: {
          ...device?.client,
          current: {
            ...device?.client?.current,
            delayMillisecond,
            displayPostNumber,
            postDescriptionTimeout,
            likeButtonStatus,
          },
        },
      }),
    ])
      .then(() => {
        messageApi.open({
          key: 'save-post',
          type: 'success',
          content: t('device.message.success.show-posts'),
        })
      })
      .catch(() => {
        messageApi.open({
          key: 'save-post',
          type: 'error',
          content: t('device.message.error.show-posts'),
        })
      })
  }

  return (
    <Col span={24}>
      {contextHolderMessage}
      <Title level={4}>{t('device.title.post')}</Title>
      <Form style={{ marginTop: '32px', width: '100%' }}>
        <Form.Item label={t('device.number-posts')}>
          <InputNumber
            min={0}
            max={100}
            value={displayPostNumber}
            onChange={(value) => setDisplayPostNumber(value || 0)}
          />
        </Form.Item>
        <Form.Item label={t('device.display-time')}>
          <InputNumber
            min={0}
            max={100}
            value={delayMillisecond / 1000}
            onChange={(value) => setDelayMillisecond((value || 0) * 1000)}
          />
        </Form.Item>
        <Form.Item label={t('device.display-time.image-title')}>
          <InputNumber
            min={0}
            max={100}
            value={postDescriptionTimeout / 1000}
            onChange={(value) => setPostDescriptionTimeout((value || 0) * 1000)}
          />
        </Form.Item>
        <Form.Item label={t('device.reaction')}>
          <Switch
            checked={likeButtonStatus === LIKE_BUTTON_STATUS.SHOW}
            onChange={(value) => setLikeButtonStatus(value ? LIKE_BUTTON_STATUS.SHOW : LIKE_BUTTON_STATUS.HIDE)}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            disabled={
              displayPostNumber === device?.client?.current?.displayPostNumber &&
              delayMillisecond === device?.client?.current?.delayMillisecond &&
              postDescriptionTimeout === device?.client?.current?.postDescriptionTimeout &&
              likeButtonStatus === device?.client?.current?.likeButtonStatus
            }
            onClick={handleSavePost}
          >
            {t('button.save')}
          </Button>
        </Form.Item>
      </Form>
    </Col>
  )
}

export default PostSetting
