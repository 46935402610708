import { useRecoilValue } from 'recoil'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { managersRecoil } from '../../recoils/managers'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Drawer, DrawerProps, Input, notification, Space } from 'antd'
import { deviceRecoil } from '../../recoils/device'
import { currentOrganizationRecoil } from '../../recoils/organization'
import { httpsCallable } from 'firebase/functions'
import { getAuth } from 'firebase/auth'
import { functions } from '../../utils'

const AddManager = () => {
  const auth = getAuth()
  const { deviceId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { organizationId } = useRecoilValue(currentOrganizationRecoil)
  const managers = useRecoilValue(managersRecoil(''))
  const device = useRecoilValue(deviceRecoil({ deviceId, organizationId }))

  const [open] = useState(true)
  const [showError, setShowError] = useState(false)
  const [managerEmail, setManagerEmail] = useState('')
  const [isSendingInvitation, setIsSendingInvitation] = useState(false)
  const [placement] = useState<DrawerProps['placement']>('bottom')
  const [notificationApi, contextHolder] = notification.useNotification()

  const managersPossibleInvite = managers.filter((manager) => !(device.managersId || []).includes(manager.id))

  const onClose = () => {
    navigate(`/devices/${deviceId}`)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setManagerEmail(value)
    const findManager = managersPossibleInvite.find((manager) => manager.email === value)
    findManager || value === '' ? setShowError(false) : setShowError(true)
  }

  const handleAddManager = () => {
    setIsSendingInvitation(true)
    const sendInvitationEmail = httpsCallable(functions, 'SendInvitationEmail')
    const url = `${window?.location.origin}/accept-invitation/?email=${managerEmail}&inviterId=${auth.currentUser?.uid}&deviceId=${deviceId}`

    sendInvitationEmail({ continueURL: url, email: managerEmail, deviceName: device.client.current.displayName })
      .then(() => {
        setIsSendingInvitation(false)
        notificationApi.success({
          message: t('manager.send.message.success'),
          placement: 'bottomLeft',
        })
      })
      .catch(() => {
        setIsSendingInvitation(false)
        notificationApi.error({
          message: t('manager.send.message.error'),
          placement: 'bottomLeft',
        })
      })
  }

  return (
    <Drawer
      title={t('manager.button.add')}
      closable={false}
      placement={placement}
      onClose={onClose}
      open={open}
      key={placement}
      extra={<Button onClick={onClose}>{t('manager.button.cancel')}</Button>}
    >
      <Space style={{ paddingBottom: '20px' }}>{t('manager.title.email-address')}</Space>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Space.Compact style={{ width: '100%' }}>
          <Input
            onChange={handleChange}
            placeholder="example@sugnee.jp"
            style={{ padding: '10px 0', textIndent: '10px', fontSize: '14px', fontWeight: 500 }}
          />
          <Button
            loading={isSendingInvitation}
            type="primary"
            style={{
              padding: '10px 50px',
              height: 'auto',
              fontSize: '14px',
              cursor: `${showError || managerEmail === '' ? 'not-allowed' : 'pointer'}`,
              pointerEvents: `${showError || managerEmail === '' ? 'none' : 'auto'}`,
              opacity: `${showError || managerEmail === '' ? '50%' : '100%'}`,
            }}
            onClick={handleAddManager}
          >
            {t('manager.button.add')}
          </Button>
        </Space.Compact>
      </Space>
      <Space style={{ color: '#ff3141', padding: '10px 0', transition: 'ease-in-out', transitionDuration: '0.3s' }}>
        {showError && t('manager.error.invalid-account')}
      </Space>
      {contextHolder}
    </Drawer>
  )
}

export default AddManager
