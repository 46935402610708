import { Breadcrumb, Col, Image, Row, Table } from 'antd'
import React from 'react'
import { Content } from 'antd/es/layout/layout'
import { useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/es/table'
import Title from 'antd/es/typography/Title'
import { useRecoilValue } from 'recoil'
import { currentOrganizationRecoil } from '../../recoils/organization'
import { recentCallsQuery } from '../../recoils/calls'
import { recentLikedPostsRecoil } from '../../recoils/posts'
import { POST_TYPE } from '../../entities/Post'
import { convertTimestampToDateTime } from '../../utils'
import { devicesManagementRecoil } from '../../recoils/devices'
import { getAuth } from 'firebase/auth'

interface CallsType {
  key: string
  title: string
  callerName: string
  receiverName: string
  duration: number
  createdAt: string
  endedAt: string
}

interface PostsType {
  key: string
  content: any
  type: string
  imageTitle: string | undefined
  likedByDevice?: string
  updatedAt: string
  createdAt: string
}

const Dashboard: React.FC = () => {
  const { t } = useTranslation()
  const auth = getAuth()
  const breadcrumbDashboard = [
    {
      title: t('breadcrumb.dashboard'),
    },
  ]

  const recentCallsHistoryHeader: ColumnsType<CallsType> = [
    {
      title: t('call-history.table-header.title'),
      dataIndex: 'title',
    },
    {
      title: t('call-history.table-header.caller-name'),
      dataIndex: 'callerName',
    },
    {
      title: t('call-history.table-header.receiver-name'),
      dataIndex: 'receiverName',
    },
    {
      title: t('call-history.table-header.call-duration'),
      dataIndex: 'duration',
    },
    {
      title: t('call-history.table-header.created-at'),
      dataIndex: 'createdAt',
    },
    {
      title: t('call-history.table-header.ended-at'),
      dataIndex: 'endedAt',
    },
  ]

  const recentLikedPostHeader: ColumnsType<PostsType> = [
    {
      title: t('posts.table-header.content'),
      dataIndex: 'content',
    },
    {
      title: t('posts.table-header.type'),
      dataIndex: 'type',
    },
    {
      title: t('posts.table-header.image-title'),
      dataIndex: 'imageTitle',
    },
    {
      title: t('posts.table-header.liked-by-device'),
      dataIndex: 'likedByDevice',
    },
    {
      title: t('posts.table-header.update-at'),
      dataIndex: 'updatedAt',
    },
    {
      title: t('posts.table-header.create-at'),
      dataIndex: 'createdAt',
    },
  ]

  const { organizationId } = useRecoilValue(currentOrganizationRecoil)
  const recentCalls = useRecoilValue(recentCallsQuery(auth.currentUser?.uid === organizationId && organizationId))
  const devices = useRecoilValue(devicesManagementRecoil(organizationId))
  const deviceIds = devices.map(({ data }) => data.id)

  const recentLikedPosts = useRecoilValue(recentLikedPostsRecoil({ organizationId, deviceIds }))

  const recentLikedPostsData = recentLikedPosts.map(
    ({ id, content, type, description, likedByDevice, updatedAt, createdAt }) => ({
      key: id,
      content: type === POST_TYPE.TEXT ? content : <Image src={content} width={100} />,
      type: type === POST_TYPE.TEXT ? 'Text' : 'Image',
      imageTitle: description,
      likedByDevice: likedByDevice,
      updatedAt: convertTimestampToDateTime(updatedAt.seconds),
      createdAt: convertTimestampToDateTime(createdAt.seconds),
    })
  )

  return (
    <>
      <Breadcrumb items={breadcrumbDashboard} />
      <Content>
        <Row style={{ backgroundColor: '#fefefe', padding: '0 20px', marginTop: '12px' }}>
          <Col span={24}>
            <Title level={4}>{t('dashboard.recent-call-history.title')}</Title>
          </Col>
          <Col span={24}>
            {auth.currentUser?.uid === organizationId ? (
              <Table columns={recentCallsHistoryHeader} dataSource={recentCalls} />
            ) : (
              <Title level={5} type="danger">
                {t('call-history.message.permission')}
              </Title>
            )}
          </Col>
        </Row>
        <Row style={{ backgroundColor: '#fefefe', padding: '0 20px', marginTop: '32px' }}>
          <Col span={24}>
            <Title level={4}>{t('dashboard.recent-liked-post.title')}</Title>
          </Col>
          <Col span={24}>
            <Table columns={recentLikedPostHeader} dataSource={recentLikedPostsData} />
          </Col>
        </Row>
      </Content>
    </>
  )
}

export default Dashboard
