import React from 'react'
import { useRecoilValue } from 'recoil'
import { getDeviceManagers } from '../../recoils/managers'
import { Button, Col, Row, Space, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import { deviceRecoil } from '../../recoils/device'
import { useNavigate } from 'react-router-dom'
import Title from 'antd/es/typography/Title'

interface ManagersProps {
  deviceId: string | undefined
  organizationId: string
}

interface DataType {
  key: string
  displayName: string
  email: string
  action: any
}

const Managers: React.FC<ManagersProps> = ({ deviceId, organizationId }) => {
  const { t } = useTranslation()
  const device = useRecoilValue(deviceRecoil({ deviceId, organizationId }))
  const managers = useRecoilValue(getDeviceManagers({ device }))

  const navigate = useNavigate()

  const managersDataSource = managers.map(({ id, email, displayName }) => ({
    key: id,
    displayName,
    email,
    action: (
      <Space>
        <Button type="link" block onClick={() => navigate(`manager/${id}/delete`)}>
          {t('manager.action.delete')}
        </Button>
      </Space>
    ),
  }))

  const columns: ColumnsType<DataType> = [
    {
      title: t('manager.table-header.displayName'),
      dataIndex: 'displayName',
    },
    {
      title: t('manager.table-header.email'),
      dataIndex: 'email',
    },
    {
      title: t('manager.table-header.action'),
      dataIndex: 'action',
    },
  ]

  return (
    <Row style={{ padding: '0 32px 16px', backgroundColor: '#fefefe', marginTop: '32px' }}>
      <Col span={12}>
        <Title level={4}>{t('manager.title')}</Title>
      </Col>
      <Col span={24}>
        <Space style={{ width: '100%', justifyContent: 'end', marginBottom: '16px' }}>
          <Button type="primary" onClick={() => navigate('managers/add')}>
            {t('manager.button.add')}
          </Button>
        </Space>
        <Table columns={columns} dataSource={managersDataSource} />
      </Col>
    </Row>
  )
}

export default Managers
