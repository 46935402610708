import { atomFamily } from 'recoil'
import { DeviceManagement } from '../entities/Device'
import { httpsCallable } from 'firebase/functions'
import { collection, getFirestore, onSnapshot, query } from 'firebase/firestore'
import { functions } from '../utils'

export const getDevicesManagement = async (organization: string) => {
  const getManagementDevices = httpsCallable(functions, 'GetManagementDevices')
  const response: any = await getManagementDevices({ organization })
  if (response.error) {
    throw response.error
  }
  return response.data.data as DeviceManagement[]
}

export const devicesManagementRecoil = atomFamily<DeviceManagement[], string>({
  key: 'devicesManagement',
  default: (organization) => getDevicesManagement(organization),
  effects: (organization) => {
    const db = getFirestore()
    return [
      ({ setSelf }) => {
        if (!organization) {
          return setSelf([])
        }
        const devicesQuery = query(collection(db, `/organizations/${organization}/devices`))
        return onSnapshot(devicesQuery, async () => {
          const newDevicesManagement = await getDevicesManagement(organization)
          setSelf(newDevicesManagement)
        })
      },
    ]
  },
})
