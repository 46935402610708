import { atom } from 'recoil'
import Organization from '../entities/Organization'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../utils'

export const getOrganizationsApi = async () => {
  const getOrganizationAccountBelong = httpsCallable(functions, 'GetOrganizationAccountBelong')
  const result: any = await getOrganizationAccountBelong({})
  return result.data.data.organizations as Organization[]
}

export const organizationsRecoil = atom<Organization[]>({
  key: 'organizations',
  default: [
    {
      id: '',
      avatar: '',
      displayName: '',
      limit: {
        callTime: 0,
        devices: 0,
        maxCallDuration: 0,
        posts: 0,
        remainingCallTime: 0,
        totalCallTime: 0,
      },
    },
  ],
})
