import { Device } from '../entities/Device'
import { atomFamily, selectorFamily } from 'recoil'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../utils'

export type Manager = {
  id: string
  avatar: string
  displayName: string
  email: string
}

export const managersRecoil = atomFamily<Manager[], string>({
  key: 'managers.list',
  default: async () => {
    const getManagers = httpsCallable<{}, { status: string; data: { managers: Manager[] } }>(functions, 'GetManagers')
    const response = await getManagers({})

    return response.data.data.managers
  },
})

export const getDeviceManagers = selectorFamily<Manager[], { device: Device }>({
  key: 'manager.device',
  get:
    ({ device }) =>
    ({ get }) => {
      const managers = get(managersRecoil(''))
      return managers.filter((manager) => (device.managersId || []).includes(manager.id))
    },
})
