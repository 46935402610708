import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Modal } from 'antd'
import { getAuth, signOut } from 'firebase/auth'
import { useTranslation } from 'react-i18next'

const Logout: React.FC = () => {
  const auth = getAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleLogout = () => {
    signOut(auth)
      .then(() => navigate('/login'))
      .catch((error) => {
        throw new Error(error.message)
      })
  }

  return (
    <Modal
      title={t('setting.account.logout.title')}
      centered
      open={true}
      okText={t('setting.account.logout.button.ok')}
      onOk={handleLogout}
      okType="primary"
      okButtonProps={{ danger: true }}
      cancelText={t('setting.account.logout.button.cancel')}
      onCancel={() => navigate('/settings')}
    >
      {t('setting.account.logout.confirm-message')}
    </Modal>
  )
}

export default Logout
