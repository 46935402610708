import { atomFamily, selectorFamily } from 'recoil'
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import { Call } from '../entities/Call'
import Organization from '../entities/Organization'
import { Device } from '../entities/Device'
import { convertTimestampToDateTime } from '../utils'

type CallsParams = {
  organizationId: string
  startDate: Timestamp
  endDate: Timestamp
}

export const callsRecoil = atomFamily<Call[], CallsParams>({
  key: 'calls',
  default: async ({ organizationId, startDate, endDate }) => {
    if (!organizationId) {
      return []
    }
    const db = getFirestore()
    const callsQuery = query(
      collection(db, `/organizations/${organizationId}/calls`),
      where('createdAt', '>=', startDate),
      where('createdAt', '<=', endDate),
      orderBy('createdAt')
    )

    const callsSnapshot = await getDocs(callsQuery)

    const calls = callsSnapshot.docs.map((call) => ({
      ...call.data(),
      id: call.id,
    }))

    return calls as Call[]
  },
  effects: ({ organizationId, startDate, endDate }) => {
    const db = getFirestore()

    return [
      ({ setSelf }) => {
        if (!organizationId) {
          return setSelf([])
        }

        const callsQuery = query(
          collection(db, `/organizations/${organizationId}/calls`),
          where('createdAt', '>=', startDate),
          where('createdAt', '<=', endDate),
          orderBy('createdAt')
        )

        return onSnapshot(callsQuery, (snapshot) => {
          setSelf(
            snapshot.docs.map((call) => ({
              ...call.data(),
              id: call.id,
            })) as Call[]
          )
        })
      },
    ]
  },
})

const CALLER_TYPE = {
  BROWSER: 'BROWSER',
  DEVICE: 'DEVICE',
}

const getOrganizationName = selectorFamily({
  key: 'getOrganizationName',
  get: (ref: string) => async () => {
    const db = getFirestore()
    const snapshot = await getDoc(doc(db, ref))
    const userData = snapshot.data() as Organization
    return userData?.displayName || ''
  },
})

const getDeviceName = selectorFamily({
  key: 'getDeviceName',
  get: (ref: string) => async () => {
    const db = getFirestore()
    const snapshot = await getDoc(doc(db, ref))
    const userData = snapshot.data() as Device
    return userData?.client?.current?.displayName || ''
  },
})

export const callInfoQuery = selectorFamily({
  key: 'callInfoQuery',
  get:
    ({ organizationId, startDate, endDate }: CallsParams) =>
    ({ get }) => {
      const callsData = get(callsRecoil({ organizationId, startDate, endDate }))
      return callsData.map(({ id, status, caller, receiver, duration, createdAt, endedAt }) => ({
        key: id,
        title: status,
        callerName:
          caller.__type === CALLER_TYPE.BROWSER
            ? get(getOrganizationName(caller.__ref))
            : get(getDeviceName(caller.__ref)),
        receiverName: get(getDeviceName(receiver.ref)),
        duration,
        createdAt: convertTimestampToDateTime(createdAt?.seconds || 0),
        endedAt: convertTimestampToDateTime(endedAt?.seconds || 0),
      }))
    },
})

export const recentCallsRecoil = atomFamily<Call[], string>({
  key: 'recentCallsRecoil',
  default: async (organizationId) => {
    if (!organizationId) {
      return []
    }
    const db = getFirestore()
    const callsQuery = query(
      collection(db, `/organizations/${organizationId}/calls`),
      orderBy('createdAt', 'desc'),
      limit(10)
    )

    const callsSnapshot = await getDocs(callsQuery)

    const calls = callsSnapshot.docs.map((call) => ({
      ...call.data(),
      id: call.id,
    }))

    return calls as Call[]
  },
  effects: (organizationId) => {
    if (!organizationId) {
      return []
    }
    const db = getFirestore()

    return [
      ({ setSelf }) => {
        const callsQuery = query(
          collection(db, `/organizations/${organizationId}/calls`),
          orderBy('createdAt', 'desc'),
          limit(10)
        )

        return onSnapshot(callsQuery, (snapshot) => {
          setSelf(
            snapshot.docs.map((call) => ({
              ...call.data(),
              id: call.id,
            })) as Call[]
          )
        })
      },
    ]
  },
})

export const recentCallsQuery = selectorFamily({
  key: 'recentCallsQuery',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const recentCallsData = get(recentCallsRecoil(organizationId))
      return recentCallsData.map(({ id, status, caller, receiver, duration, createdAt, endedAt }) => ({
        key: id,
        title: status,
        callerName:
          caller.__type === CALLER_TYPE.BROWSER
            ? get(getOrganizationName(caller.__ref))
            : get(getDeviceName(caller.__ref)),
        receiverName: get(getDeviceName(receiver.ref)),
        duration,
        createdAt: convertTimestampToDateTime(createdAt?.seconds || 0),
        endedAt: convertTimestampToDateTime(endedAt?.seconds || 0),
      }))
    },
})
