import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, message, Modal, Space } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../../utils'

const DeleteManager = () => {
  const navigate = useNavigate()
  const { managerId, deviceId } = useParams()

  const { t } = useTranslation()
  const [confirmLoading, setConfirmLoading] = useState(false)

  const handleOk = () => {
    setConfirmLoading(true)
    const deleteManager = httpsCallable(functions, 'DeleteManager')
    deleteManager({ managerId, deviceId })
      .then(() => {
        setConfirmLoading(false)
        message.success(t('manager.delete.success'), 3)
        navigate(`/devices/${deviceId}`)
      })
      .catch(() => {
        setConfirmLoading(false)
        message.error(t('manager.delete.error'), 3)
        navigate(`/devices/${deviceId}`)
      })
  }

  return (
    <Modal
      centered
      title={t('manager.button.delete-manager')}
      onCancel={() => navigate(`/devices/${deviceId}`)}
      open={true}
      footer={
        <Space style={{ width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
          <Button onClick={() => navigate(`/devices/${deviceId}`)}> {t('manager.delete.button.no')}</Button>
          <Button loading={confirmLoading} onClick={handleOk} type="primary" danger>
            {t('manager.delete.button.yes')}
          </Button>
        </Space>
      }
    >
      <p>{t('manager.modal.delete.content')}</p>
    </Modal>
  )
}

export default DeleteManager
