import React from 'react'
import { useRecoilValue } from 'recoil'
import { deviceRecoil } from '../../recoils/device'
import { Space } from 'antd'

type DeviceInformationProps = {
  organizationId: string
  deviceId: string
}

const DeviceInformation: React.FC<DeviceInformationProps> = ({ organizationId, deviceId }) => {
  const deviceCurrent = useRecoilValue(deviceRecoil({ organizationId, deviceId }))

  return (
    <Space wrap>
      <div style={{ marginRight: '32px' }}>
        <div
          style={{
            width: '70px',
            height: '70px',
            backgroundImage: `url('${deviceCurrent?.client?.current?.avatar}')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            borderRadius: 'var(--avatar-border-radius)',
          }}
        />
        <Space style={{ fontSize: '12px', fontWeight: '400', color: '#4E4E4E', marginTop: '12px' }}>
          {deviceCurrent?.client?.current?.displayName}
        </Space>
      </div>
    </Space>
  )
}

export default DeviceInformation
