import React, { useState } from 'react'
import { Breadcrumb, Button, Col, Form, Input, Row, Space, message } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { useTranslation } from 'react-i18next'
import Title from 'antd/es/typography/Title'
import { UploadFile, UploadProps } from 'antd/es/upload'
import { User, getAuth, updateProfile } from 'firebase/auth'
import { profileRecoil } from '../../recoils/profile'
import { useRecoilState } from 'recoil'
import UploadImage from '../uploadImage'
import { getStorageUrl, upload, uploadStorage } from '../../utils'
import { Outlet, useNavigate } from 'react-router-dom'

const Settings: React.FC = () => {
  const auth = getAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { uid, email } = auth.currentUser as User
  const [profile, setProfile] = useRecoilState(profileRecoil(uid))
  const [messageApi, contextHolder] = message.useMessage()

  const [uploadError, setUploadError] = useState(false)
  const [fileList, setFileList] = useState<UploadFile[]>(upload({ uid: profile.id, url: profile?.avatar }))
  const [displayName, setDisplayName] = useState(profile?.displayName)

  const breadcrumbSettings = [
    {
      title: t('breadcrumb.settings'),
    },
  ]

  const handleChangeImage: UploadProps['onChange'] = async ({ fileList: newFileList, file: newFile }) => {
    if (uploadError) return

    if (newFileList.length === 0) {
      setFileList(newFileList)
      return
    }

    messageApi.open({
      key: 'changeAvatar',
      type: 'loading',
      content: t('setting.profile.avatar.message.saving'),
      duration: 0,
    })

    Promise.all([uploadStorage({ newFile, url: `images/avatars/${newFileList[0]?.uid}` })]).then((path) => {
      Promise.all([getStorageUrl(path[0])]).then((downloadURLs) => {
        Promise.all([
          setProfile({
            ...profile,
            avatar: downloadURLs[0],
          }),
          updateProfile(auth.currentUser as User, {
            photoURL: downloadURLs[0],
          }),
        ])
          .then(() => {
            setFileList(newFileList)
            messageApi.open({
              key: 'changeAvatar',
              type: 'success',
              content: t('setting.profile.avatar.message.success'),
              duration: 5,
            })
          })
          .catch((error) => {
            messageApi.open({
              key: 'changeAvatar',
              type: 'error',
              content: error.message,
              duration: 5,
            })
            throw new Error(error.message)
          })
      })
    })
  }

  const handleSaveDisplayName = async () => {
    await Promise.all([
      setProfile({
        ...profile,
        displayName,
      }),
      updateProfile(auth.currentUser as User, {
        displayName,
      }),
    ])
      .then(() => message.success(t('setting.profile.display-name.message.success'), 3))
      .catch((error) => {
        message.error(error.message, 3)
        throw new Error(error.message)
      })
  }

  return (
    <>
      <Breadcrumb items={breadcrumbSettings} />
      <Content style={{ marginTop: '20px', minHeight: 280 }}>
        <Row style={{ padding: '0 32px 40px', backgroundColor: '#fefefe', height: '100%' }}>
          <Col span={12}>
            <Title level={4}>{t('setting.profile.title')}</Title>
          </Col>
          <Col span={24}>
            <Form labelCol={{ span: 4 }} style={{ maxWidth: 600, marginTop: '16px' }}>
              <Form.Item label={t('setting.profile.avatar')}>
                <UploadImage
                  fileList={fileList}
                  setUploadError={setUploadError}
                  handleChangeImage={handleChangeImage}
                />
              </Form.Item>
              <Form.Item label={t('setting.profile.display-name')}>
                <Input
                  placeholder={t('setting.profile.display-name.placeholder') || 'Input display name'}
                  value={displayName}
                  onChange={(event) => setDisplayName(event.target.value)}
                  onBlur={handleSaveDisplayName}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Row style={{ marginTop: '32px', padding: '0 32px 40px', backgroundColor: '#fefefe', height: '100%' }}>
          <Col span={12}>
            <Title level={4}>{t('setting.account.title')}</Title>
          </Col>
          <Col span={24}>
            <Form labelCol={{ span: 4 }} style={{ maxWidth: 600, marginTop: '16px' }}>
              <Form.Item label={t('setting.account.email')}>
                <Space>
                  <Input value={email || ''} disabled />
                  <Button onClick={() => navigate('email')}>{t('setting.account.button.reset')}</Button>
                </Space>
              </Form.Item>
              <Form.Item label={t('setting.account.password')}>
                <Space>
                  <Input type="password" value="********" disabled autoComplete="off" />
                  <Button onClick={() => navigate('password')}>{t('setting.account.button.reset')}</Button>
                </Space>
              </Form.Item>
              <Form.Item>
                <Button onClick={() => navigate('logout')}>{t('setting.account.button.logout')}</Button>
              </Form.Item>
              <Form.Item>
                <Button danger type="primary" onClick={() => navigate('delete-account')}>
                  {t('setting.account.button.delete-account')}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Outlet />
        {contextHolder}
      </Content>
    </>
  )
}

export default Settings
