import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { languageCurrentRecoil } from '../recoils/language'
import { useRecoilValue } from 'recoil'
import SpinLoading from '../components/loading'
import { Outlet } from 'react-router-dom'

const I18nProvider: React.FC = () => {
  const { i18n } = useTranslation()
  const [loadingI18n, setLoadingI18n] = useState(true)
  const languageCurrent = useRecoilValue(languageCurrentRecoil)

  useEffect(() => {
    i18n.changeLanguage(languageCurrent).then(() => setLoadingI18n(false))
  }, [i18n, languageCurrent])

  if (loadingI18n) return <SpinLoading />

  return <Outlet />
}

export default I18nProvider
