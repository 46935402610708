import React, { useRef, useEffect, useState } from 'react'
import Video from 'twilio-video'
import { httpsCallable } from 'firebase/functions'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import Participant from './Participant'
import { doc, getFirestore, onSnapshot } from 'firebase/firestore'
import { CALL_STATUS } from '../../constants'
import { Image, Space, message } from 'antd'
import { callRecoil } from '../../recoils/call'
import CallLayout from './CallLayout'
import { DEFAULT_AVATAR, functions } from '../../utils'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Content } from 'antd/es/layout/layout'

const cancelCall = httpsCallable(functions, 'CancelCall')

const GuestCall: React.FC = () => {
  const db = getFirestore()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const url = new URL(window.location.href)
  const deviceId = url.searchParams.get('deviceId') as string
  const organizationId = url.searchParams.get('organizationId') as string
  const displayName = url.searchParams.get('displayName') as string
  const linkId = url.searchParams.get('linkId') as string
  const loaded = useRef(false)
  const videoPreviewRef = useRef<any>(null)
  const audioTrackRef = useRef<any>(null)
  const videoTrackRef = useRef<any>(null)
  const [token, setToken] = useState('')
  const [callRef, setCallRef] = useState('')
  const callSetting = useRecoilValue(callRecoil)
  const [room, setRoom] = useState<any>(null)
  const resetCallState = useResetRecoilState(callRecoil)
  const [participants, setParticipants] = useState<any[]>([])
  const [callEnded, setCallEnded] = useState(false)

  //handle call refused
  useEffect(() => {
    if (callRef) {
      const callCollection = doc(db, callRef)
      onSnapshot(callCollection, (snapshot: any) => {
        if (snapshot.data().status === CALL_STATUS.REFUSED) {
          message.error(t('call.message.error.refused'), 5, () => setCallEnded(true))
        }
      })
    }
  }, [callRef, db, deviceId, navigate, t])

  //handle end call
  useEffect(() => {
    if (callSetting.endCall) {
      room.localParticipant.videoTracks.forEach((publication: any) => {
        publication.track.stop()
        publication.unpublish()
      })
      room.localParticipant.audioTracks.forEach((publication: any) => {
        publication.track.stop()
        publication.unpublish()
      })

      setCallEnded(true)
      // When end call
      return () => {
        if (room) {
          room.localParticipant.videoTracks.forEach((publication: any) => {
            publication.track.stop()
            publication.unpublish()
          })
          room.localParticipant.audioTracks.forEach((publication: any) => {
            publication.track.stop()
            publication.unpublish()
          })

          participants.length
            ? room.disconnect()
            : cancelCall({ callRef }).catch((error) => {
                throw new Error(error.message)
              })
        } else {
          audioTrackRef.current && audioTrackRef.current.stop()
          videoTrackRef.current && videoTrackRef.current.stop()
        }

        resetCallState()
      }
    }
  }, [callRef, callSetting.endCall, deviceId, navigate, participants.length, resetCallState, room])

  //handle on/off camera
  useEffect(() => {
    if (room) {
      if (callSetting.muteCamera) {
        room.localParticipant.videoTracks.forEach((publication: any) => {
          publication.track.disable()
          publication.track.restart()
        })
      } else {
        room.localParticipant.videoTracks.forEach((publication: any) => {
          publication.track.enable()
          publication.track.restart()
        })
      }
    }
  }, [callSetting.muteCamera, room])

  //handle on/off microphone
  useEffect(() => {
    if (room) {
      if (callSetting.muteMicrophone) {
        room.localParticipant.audioTracks.forEach((publication: any) => {
          publication.track.disable()
          publication.track.restart()
        })
      } else {
        room.localParticipant.audioTracks.forEach((publication: any) => {
          publication.track.enable()
          publication.track.restart()
        })
      }
    }
  }, [callSetting.muteMicrophone, room])

  //handle call
  useEffect(() => {
    if (!loaded.current) {
      loaded.current = true
      const makeCallGuest = httpsCallable(functions, 'MakeCallGuest')

      Promise.all([
        Video.createLocalVideoTrack({ name: 'camera' }),
        Video.createLocalAudioTrack({ name: 'microphone' }),
      ])
        .then((localTracks) => {
          videoTrackRef.current = localTracks[0]
          audioTrackRef.current = localTracks[1]
          if (!videoPreviewRef.current?.hasChildNodes()) {
            const localEl = localTracks[0]?.attach() as HTMLMediaElement
            localEl.style.position = 'absolute'
            localEl.style.width = '100%'
            localEl.style.height = '100%'
            localEl.style.objectFit = 'cover'
            localEl.style.filter = 'blur(8px)'

            videoPreviewRef.current?.appendChild(localEl)
          }

          makeCallGuest({ organization: organizationId, id: deviceId, linkId })
            .then((result: any) => {
              setToken(result.data.data.token)
              setCallRef(result.data.data.callRef)

              //connect room
              Video.connect(result.data.data.token, {
                name: result.data.data.token,
                tracks: localTracks,
              }).then((roomResult: Video.Room) => {
                setRoom(roomResult)
                roomResult.on('disconnected', (room: Video.Room) => {
                  room.localParticipant.tracks.forEach((publication: any) => {
                    const attachedElements = publication.track.detach()
                    attachedElements.forEach((element: any) => element.remove())
                    publication.track.stop()
                    publication.unpublish()
                  })
                  setCallEnded(true)
                })
                roomResult.on('participantConnected', (participant: Video.Participant) => {
                  setParticipants([participant])
                })
              })
            })
            .catch((error) => {
              window.alert(error.message)
              setCallEnded(true)
              throw new Error(error.message)
            })
        })
        .catch((error) => {
          window.alert(error.message)
          setCallEnded(true)
          throw new Error(error.message)
        })
    }
    return () => {
      setRoom((currentRoom: Video.Room) => {
        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
          currentRoom.localParticipant.tracks.forEach(function (trackPublication: any) {
            trackPublication.track.stop()
          })
          currentRoom.disconnect()
          return null
        } else {
          return currentRoom
        }
      })
      resetCallState()
    }
  }, [deviceId, linkId, navigate, organizationId, resetCallState])

  if (callEnded)
    return (
      <Content
        style={{
          backgroundColor: '#EEF0F3',
          padding: '15% 0',
          height: '100%',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Image src="/images/sugnee.svg" width={180} height={211} />
        <Space style={{ margin: '16px 0 8px', fontSize: '48px', fontWeight: '700' }}>
          {t('call.end-call.message1')}
        </Space>
        <Space style={{ fontSize: '16px', fontWeight: '400' }}>{t('call.end-call.message2')}</Space>
      </Content>
    )

  return (
    <CallLayout room={room} isGuestCall>
      {token && room && participants.length ? (
        <>
          <div style={{ width: '150px', position: 'absolute', top: '20px', left: '20px', zIndex: 2000 }}>
            {room && <Participant key={room.localParticipant.sid} participant={room.localParticipant} />}
          </div>
          {participants.map((participant) => (
            <Participant key={participant.sid} participant={participant} />
          ))}
        </>
      ) : (
        <>
          <Space
            direction="vertical"
            style={{ backgroundColor: '#eeeeee', position: 'absolute', top: '20px', left: '20px', zIndex: 2000 }}
          >
            <div
              style={{
                width: '150px',
                height: '112px',
                backgroundImage: `url('${DEFAULT_AVATAR}')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                borderRadius: 'var(--avatar-border-radius)',
                border: '1px solid #eeeeee',
              }}
            />
            <Space style={{ fontSize: '15px', margin: '16px 0 0 40px' }}>{displayName.replaceAll('+', ' ')}</Space>
          </Space>
          <div ref={videoPreviewRef} />
        </>
      )}
    </CallLayout>
  )
}

export default GuestCall
