import React from 'react'
import { Breadcrumb, Row } from 'antd'
import Title from 'antd/es/typography/Title'
import { Outlet, useParams } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { currentOrganizationRecoil } from '../../../recoils/organization'
import { deviceRecoil } from '../../../recoils/device'
import { devicesManagementRecoil } from '../../../recoils/devices'
import { Content } from 'antd/es/layout/layout'
import { useTranslation } from 'react-i18next'
import Posts from './Posts'
import Managers from '../../managers'
import { getAuth } from 'firebase/auth'
import ContactList from './ContactList'
import PostSetting from './PostSetting'
import DeviceInfo from './DeviceInfo'
import Other from './Other'

const DeviceDetail: React.FC = () => {
  const auth = getAuth()
  const { deviceId } = useParams()
  const { t } = useTranslation()

  const { organizationId } = useRecoilValue(currentOrganizationRecoil)
  const [device, setDevice] = useRecoilState(deviceRecoil({ organizationId, deviceId }))
  const devicesManagement = useRecoilValue(devicesManagementRecoil(organizationId))

  const breadcrumbDevice = [
    {
      title: t('breadcrumb.devices'),
      href: '/devices',
    },
    {
      title: deviceId,
    },
  ]

  if (!device)
    return (
      <>
        <Breadcrumb items={breadcrumbDevice} />
        <Content className="site-layout" style={{ marginTop: '20px', padding: '20px', backgroundColor: '#fefefe' }}>
          <Title level={5} type="danger">
            {t('device.message.not-exist')}
          </Title>
        </Content>
      </>
    )

  return (
    <>
      <Breadcrumb items={breadcrumbDevice} />
      <Content className="site-layout" style={{ marginTop: '20px', minHeight: 280 }}>
        <DeviceInfo organizationId={organizationId} deviceId={deviceId} device={device} setDevice={setDevice} />

        <ContactList organizationId={organizationId} deviceId={deviceId} device={device} setDevice={setDevice} />

        <Row style={{ padding: '0 32px 16px', backgroundColor: '#fefefe', marginTop: '32px' }}>
          <PostSetting device={device} setDevice={setDevice} />
          <Posts deviceId={deviceId} />
        </Row>
        {devicesManagement.find((device) => device.organization === auth.currentUser?.uid) && (
          <Managers deviceId={deviceId} organizationId={organizationId} />
        )}

        {auth.currentUser?.uid === organizationId && <Other />}
      </Content>
      <Outlet />
    </>
  )
}

export default DeviceDetail
