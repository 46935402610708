import React from 'react'
import { useTranslation } from 'react-i18next'
import DeviceInformation from './DeviceInformation'
import TextArea from 'antd/es/input/TextArea'

type PostMessageProps = {
  organizationId: string
  deviceIds: string[]
  posted: boolean
  postMessage: string
  setPostMessage: (value: string) => void
}

const PostMessage: React.FC<PostMessageProps> = ({
  organizationId,
  deviceIds,
  posted,
  postMessage,
  setPostMessage,
}) => {
  const { t } = useTranslation()
  return (
    <div style={{ padding: '20px' }}>
      {deviceIds.map((deviceId) => (
        <DeviceInformation key={deviceId} organizationId={organizationId} deviceId={deviceId} />
      ))}
      <TextArea
        style={{ marginTop: '36px' }}
        showCount
        maxLength={15}
        disabled={posted}
        placeholder={t('post.content.placeholder') || ''}
        defaultValue={postMessage}
        onChange={(e) => setPostMessage(e.target.value)}
      />
    </div>
  )
}

export default PostMessage
