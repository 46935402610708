import React from 'react'
import { Button, Col, Image, Space, Table } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { currentOrganizationRecoil } from '../../../recoils/organization'
import { useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/es/table'
import { postsRecoil } from '../../../recoils/posts'
import { POST_TYPE } from '../../../entities/Post'
import { convertTimestampToDateTime } from '../../../utils'

interface DataType {
  key: string
  content: any
  type: string
  imageTitle: string | undefined
  createByUser: string
  updatedAt: string
  createdAt: string
  action: any
}

const Posts: React.FC<{ deviceId: string | undefined }> = ({ deviceId }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { organizationId } = useRecoilValue(currentOrganizationRecoil)
  const posts = useRecoilValue(postsRecoil({ organizationId, deviceId }))

  const postsData = posts.map(({ id, content, type, description, createdByUser, updatedAt, createdAt }) => ({
    key: id,
    content: type === POST_TYPE.TEXT ? content : <Image src={content} width={100} />,
    type: type === POST_TYPE.TEXT ? 'Text' : 'Image',
    imageTitle: description,
    createByUser: createdByUser.displayName,
    updatedAt: convertTimestampToDateTime(updatedAt.seconds),
    createdAt: convertTimestampToDateTime(createdAt.seconds),
    action: (
      <Space>
        <Button type="link" block onClick={() => navigate(`posts/edit/${id}`)}>
          {t('posts.action.edit')}
        </Button>
        <Button type="link" block onClick={() => navigate(`posts/delete/${id}`)}>
          {t('posts.action.delete')}
        </Button>
      </Space>
    ),
  }))

  const columns: ColumnsType<DataType> = [
    {
      title: t('posts.table-header.content'),
      dataIndex: 'content',
    },
    {
      title: t('posts.table-header.type'),
      dataIndex: 'type',
    },
    {
      title: t('posts.table-header.image-title'),
      dataIndex: 'imageTitle',
    },
    {
      title: t('posts.table-header.create-by-user'),
      dataIndex: 'createByUser',
    },
    {
      title: t('posts.table-header.update-at'),
      dataIndex: 'updatedAt',
    },
    {
      title: t('posts.table-header.create-at'),
      dataIndex: 'createdAt',
    },
    {
      title: t('posts.table-header.action'),
      dataIndex: 'action',
    },
  ]

  return (
    <Col span={24}>
      <Space style={{ width: '100%', justifyContent: 'end', marginBottom: '16px' }}>
        <Button type="primary" onClick={() => navigate('posts/add')}>
          {t('post.button.add')}
        </Button>
      </Space>
      <Table columns={columns} dataSource={postsData} />
    </Col>
  )
}

export default Posts
