import React, { useRef, useEffect, Suspense, useState } from 'react'
import Video from 'twilio-video'
import CalleeInformation from './CalleeInformation'
import { httpsCallable } from 'firebase/functions'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import { currentOrganizationRecoil, organizationRecoil } from '../../recoils/organization'
import Participant from './Participant'
import { doc, getFirestore, onSnapshot } from 'firebase/firestore'
import { CALL_STATUS } from '../../constants'
import { message } from 'antd'
import { callRecoil } from '../../recoils/call'
import CallLayout from './CallLayout'
import { functions } from '../../utils'
import { useTranslation } from 'react-i18next'

const cancelCall = httpsCallable(functions, 'CancelCall')

const Call: React.FC = () => {
  const db = getFirestore()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { deviceId } = useParams()
  const loaded = useRef(false)
  const ended = useRef(false)
  const videoPreviewRef = useRef<any>(null)
  const audioTrackRef = useRef<any>(null)
  const videoTrackRef = useRef<any>(null)
  const [token, setToken] = useState('')
  const [callRef, setCallRef] = useState('')
  const callSetting = useRecoilValue(callRecoil)
  const [room, setRoom] = useState<any>(null)
  const resetCallState = useResetRecoilState(callRecoil)
  const [participants, setParticipants] = useState<any[]>([])
  const { organizationId } = useRecoilValue(currentOrganizationRecoil)
  const organization = useRecoilValue(organizationRecoil(organizationId))

  //handle call refused
  useEffect(() => {
    if (callRef) {
      const callCollection = doc(db, callRef)
      onSnapshot(callCollection, (snapshot: any) => {
        if (snapshot.data().status === CALL_STATUS.REFUSED) {
          message.error(t('call.message.error.refused'), 5, () => navigate('/'))
        }
      })
    }
  }, [callRef, db, navigate, t])

  //handle end call
  useEffect(() => {
    //End the call when the time limit comes
    if (!ended.current && room) {
      const timeCall = (organization?.limit?.remainingCallTime || 1800) * 1000
      ended.current = true
      setTimeout(() => {
        message.info(t('call.message.error.limit'), 5, () => navigate('/'))

        room.localParticipant.videoTracks.forEach((publication: any) => {
          publication.track.stop()
          publication.unpublish()
        })
        room.localParticipant.audioTracks.forEach((publication: any) => {
          publication.track.stop()
          publication.unpublish()
        })
      }, timeCall)
    }

    if (callSetting.endCall) {
      room.localParticipant.videoTracks.forEach((publication: any) => {
        publication.track.stop()
        publication.unpublish()
      })
      room.localParticipant.audioTracks.forEach((publication: any) => {
        publication.track.stop()
        publication.unpublish()
      })

      navigate('/')
      // When end call
      return () => {
        if (room) {
          room.localParticipant.videoTracks.forEach((publication: any) => {
            publication.track.stop()
            publication.unpublish()
          })
          room.localParticipant.audioTracks.forEach((publication: any) => {
            publication.track.stop()
            publication.unpublish()
          })

          participants.length
            ? room.disconnect()
            : cancelCall({ callRef }).catch((error) => {
                throw new Error(error.message)
              })
        } else {
          audioTrackRef.current && audioTrackRef.current.stop()
          videoTrackRef.current && videoTrackRef.current.stop()
        }

        resetCallState()
      }
    }
  }, [
    callRef,
    callSetting.endCall,
    navigate,
    organization?.limit?.remainingCallTime,
    participants.length,
    resetCallState,
    room,
    t,
  ])

  //handle on/off camera
  useEffect(() => {
    if (room) {
      if (callSetting.muteCamera) {
        room.localParticipant.videoTracks.forEach((publication: any) => {
          publication.track.disable()
          publication.track.restart()
        })
      } else {
        room.localParticipant.videoTracks.forEach((publication: any) => {
          publication.track.enable()
          publication.track.restart()
        })
      }
    }
  }, [callSetting.muteCamera, room])

  //handle on/off microphone
  useEffect(() => {
    if (room) {
      if (callSetting.muteMicrophone) {
        room.localParticipant.audioTracks.forEach((publication: any) => {
          publication.track.disable()
          publication.track.restart()
        })
      } else {
        room.localParticipant.audioTracks.forEach((publication: any) => {
          publication.track.enable()
          publication.track.restart()
        })
      }
    }
  }, [callSetting.muteMicrophone, room])

  //handle call
  useEffect(() => {
    if (!loaded.current) {
      loaded.current = true
      const makeCallBrowser = httpsCallable(functions, 'MakeCallBrowser')

      Promise.all([
        Video.createLocalVideoTrack({ name: 'camera' }),
        Video.createLocalAudioTrack({ name: 'microphone' }),
      ])
        .then((localTracks) => {
          videoTrackRef.current = localTracks[0]
          audioTrackRef.current = localTracks[1]
          if (!videoPreviewRef.current?.hasChildNodes()) {
            const localEl = localTracks[0]?.attach() as HTMLMediaElement
            localEl.style.position = 'absolute'
            localEl.style.width = '100%'
            localEl.style.height = '100%'
            localEl.style.objectFit = 'cover'
            localEl.style.filter = 'blur(8px)'

            videoPreviewRef.current?.appendChild(localEl)
          }

          makeCallBrowser({ organization: organizationId, id: deviceId })
            .then((result: any) => {
              setToken(result.data.data.token)
              setCallRef(result.data.data.callRef)

              //connect room
              Video.connect(result.data.data.token, {
                name: result.data.data.token,
                tracks: localTracks,
              }).then((roomResult: any) => {
                setRoom(roomResult)
                roomResult.on('disconnected', (room: Video.Room) => {
                  room.localParticipant.tracks.forEach((publication: any) => {
                    const attachedElements = publication.track.detach()
                    attachedElements.forEach((element: any) => element.remove())
                    publication.track.stop()
                    publication.unpublish()
                  })
                  navigate('/')
                })
                roomResult.on('participantConnected', (participant: Video.Participant) => {
                  setParticipants([participant])
                })
              })
            })
            .catch((error) => {
              message.error(t('call.message.busy'), 5, () => navigate('/'))
              throw new Error(error.message)
            })
        })
        .catch((error) => {
          window.alert(error.message)
          navigate('/')
          throw new Error(error.message)
        })
    }
    return () => {
      setRoom((currentRoom: Video.Room) => {
        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
          currentRoom.localParticipant.tracks.forEach(function (trackPublication: any) {
            trackPublication.track.stop()
          })
          currentRoom.disconnect()
          return null
        } else {
          return currentRoom
        }
      })
      resetCallState()
    }
  }, [deviceId, navigate, organizationId, resetCallState, t])
  return (
    <CallLayout room={room}>
      {token && room && participants.length ? (
        <>
          <div style={{ width: '150px', position: 'absolute', top: '20px', left: '20px', zIndex: 2000 }}>
            {room && <Participant key={room.localParticipant.sid} participant={room.localParticipant} />}
          </div>
          {participants.map((participant) => (
            <Participant key={participant.sid} participant={participant} />
          ))}
        </>
      ) : (
        <>
          <Suspense>
            <CalleeInformation />
          </Suspense>
          <div ref={videoPreviewRef} />
        </>
      )}
    </CallLayout>
  )
}

export default Call
