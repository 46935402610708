import React from 'react'
import { useRecoilValue } from 'recoil'
import { deviceRecoil } from '../../recoils/device'
import { useParams } from 'react-router-dom'
import { currentOrganizationRecoil } from '../../recoils/organization'
import { Space } from 'antd'

const CalleeInformation: React.FC = () => {
  const { deviceId } = useParams()
  const { organizationId } = useRecoilValue(currentOrganizationRecoil)
  const device = useRecoilValue(deviceRecoil({ organizationId, deviceId: deviceId as string }))

  return (
    <Space
      direction="vertical"
      style={{ backgroundColor: '#eeeeee', position: 'absolute', top: '20px', left: '20px', zIndex: 2000 }}
    >
      <Space style={{ width: '150px', height: '112px', backgroundColor: '#756244' }}>
        <div
          style={{
            width: '150px',
            height: '112px',
            backgroundImage: `url('${device?.client?.current?.avatar}')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            borderRadius: 'var(--avatar-border-radius)',
            border: '1px solid #eeeeee',
          }}
        />
      </Space>
      <Space style={{ fontSize: '15px', margin: '16px 0 0 40px' }}>{device?.client?.current?.displayName}</Space>
      {/* <DotLoading color="primary" style={{ fontSize: '32px' }} /> */}
    </Space>
  )
}

export default CalleeInformation
