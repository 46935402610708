import React, { useState } from 'react'
import { Button, Drawer, Tabs, UploadFile, message } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { currentOrganizationRecoil } from '../../recoils/organization'
import { useTranslation } from 'react-i18next'
import { POST_TYPE } from '../../entities/Post'
import { postRecoil } from '../../recoils/post'
import { Timestamp } from 'firebase/firestore'
import { User, getAuth } from 'firebase/auth'
import PostMessage from './PostMessage'
import PostImage from './PostImage'
import { getStorageUrl, uploadStorage } from '../../utils'

const EditPost: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const auth = getAuth()
  const { uid, photoURL, displayName } = auth.currentUser as User
  const { deviceId, postId } = useParams()
  const { organizationId } = useRecoilValue(currentOrganizationRecoil)
  const [posted, setPosted] = useState(false)
  const [uploadError, setUploadError] = useState(false)

  const [post, setPost] = useRecoilState(postRecoil({ organizationId, deviceId, postId }))
  const [postType, setPostType] = useState(post.type)
  const [postImage, setPostImage] = useState<any>()
  const [postMessage, setPostMessage] = useState(post?.type === POST_TYPE.TEXT ? post?.content : '')
  const [postDescription, setPostDescription] = useState(post?.type === POST_TYPE.TEXT ? '' : post?.description || '')

  const upload = () => {
    if (post.type === POST_TYPE.IMAGE && post.content) {
      return [
        {
          uid: post.id,
          name: 'avatar',
          status: 'done',
          url: post?.content,
        },
      ] as UploadFile[]
    }
    return []
  }

  const [fileList, setFileList] = useState<UploadFile[]>(upload())

  const changeTab = (key: string) => {
    if (posted) return
    setPostType(key as POST_TYPE)
  }

  const onCloseDrawer = () => {
    navigate(`/devices/${deviceId}`)
  }

  const handleEditPost = async () => {
    if (uploadError) {
      return
    }
    setPosted(true)
    const getPath = async () => {
      if (postType === POST_TYPE.TEXT) {
        return
      }

      return uploadStorage({ newFile: postImage, url: `images/post/${fileList[0]?.uid}` })
    }

    await Promise.all([getPath()]).then((path) => {
      const getContent = async () => {
        if (postType === POST_TYPE.TEXT) {
          return postMessage
        }

        if (fileList.length === 0) {
          return post.content
        }

        return getStorageUrl(path[0])
      }
      Promise.all([getContent()]).then((contents) => {
        Promise.all([
          setPost({
            ...post,
            content: contents[0],
            description: postType === POST_TYPE.IMAGE ? postDescription : '',
            type: postType,
            createdByUser: {
              id: uid,
              avatar: photoURL || '',
              displayName: displayName || '',
            },
            updatedAt: Timestamp.now(),
          }),
        ])
          .then(() => {
            message.success(t('post.message.success'), 3, () => navigate(`/devices/${deviceId}`))
          })
          .catch((error) => {
            message.error(error.message, 3)
            throw new Error(error.message)
          })
      })
    })
  }

  return (
    <Drawer
      title={t('post.edit.title')}
      placement="bottom"
      onClose={onCloseDrawer}
      height="70vh"
      bodyStyle={{ padding: '0' }}
      closeIcon={
        <Button type="link" onClick={onCloseDrawer} disabled={posted}>
          {t('post.button.cancel')}
        </Button>
      }
      headerStyle={{ textAlign: 'center' }}
      open={true}
      extra={
        <Button type="link" disabled={posted || (postType === POST_TYPE.IMAGE && uploadError)} onClick={handleEditPost}>
          {t('post.button.save')}
        </Button>
      }
    >
      <Tabs
        onChange={changeTab}
        activeKey={postType}
        items={[
          {
            label: t('post.tab.message'),
            key: POST_TYPE.TEXT,
            children: (
              <PostMessage
                organizationId={organizationId}
                deviceIds={[deviceId as string]}
                posted={posted}
                postMessage={postMessage}
                setPostMessage={setPostMessage}
              />
            ),
          },
          {
            label: t('post.tab.image'),
            key: POST_TYPE.IMAGE,
            children: (
              <PostImage
                organizationId={organizationId}
                deviceIds={[deviceId as string]}
                postDescription={postDescription}
                setPostDescription={setPostDescription}
                setPostImage={setPostImage}
                posted={posted}
                fileList={fileList}
                setFileList={setFileList}
                setUploadError={setUploadError}
              />
            ),
          },
        ]}
      />
    </Drawer>
  )
}

export default EditPost
