import React from 'react'
import { Modal, message } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { deleteDoc, doc, getFirestore } from 'firebase/firestore'
import { useRecoilValue } from 'recoil'
import { currentOrganizationRecoil } from '../../recoils/organization'
import { postRecoil } from '../../recoils/post'
import { deleteObject, getStorage, ref } from 'firebase/storage'
import { POST_TYPE } from '../../entities/Post'
import { useTranslation } from 'react-i18next'

const DeletePost: React.FC = () => {
  const { t } = useTranslation()
  const db = getFirestore()
  const navigate = useNavigate()
  const { deviceId, postId } = useParams()
  const { organizationId } = useRecoilValue(currentOrganizationRecoil)
  const post = useRecoilValue(postRecoil({ organizationId, deviceId, postId }))

  const deletePost = () => {
    deleteDoc(doc(db, `/organizations/${organizationId}/devices/${deviceId}/posts`, postId as string))
      .then(() => {
        if (post.type === POST_TYPE.IMAGE) {
          //Delete image on storage
          deleteObject(ref(getStorage(), post.content))
        }
        message.success(t('post.delete.message.success'), 4)
        navigate(`/devices/${deviceId}`)
      })
      .catch((error) => {
        message.error(t('post.delete.message.error'), 4)
        throw new Error(error.message)
      })
  }

  return (
    <Modal
      title={t('post.delete.title')}
      centered
      open={true}
      okText={t('post.delete.button.yes')}
      onOk={deletePost}
      okType="danger"
      cancelText={t('post.delete.button.no')}
      onCancel={() => navigate(`/devices/${deviceId}`)}
    >
      {t('post.delete.message.confirm')}
    </Modal>
  )
}

export default DeletePost
