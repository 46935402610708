import { getAuth, isSignInWithEmailLink, updateEmail, User, signOut } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useRef } from 'react'
import { signInWithEmailLink } from '@firebase/auth'
import SpinLoading from '../loading'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'

const ChangeEmail: React.FC = () => {
  const auth = getAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const ref = useRef(false)

  useEffect(() => {
    if (!ref.current && isSignInWithEmailLink(auth, window.location.href)) {
      ref.current = true
      const url = new URL(window.location.href)
      const email = url.searchParams.get('email') as string
      const newEmail = url.searchParams.get('newEmail')
      signInWithEmailLink(auth, email as string, window.location.href)
        .then(async () => {
          updateEmail(auth.currentUser as User, newEmail as string)
            .then(() => {
              message.success(t('setting.account.email.message.update.success'), 5)
              navigate('/')
            })
            .catch((error) => {
              message.error(error.message, 5)
              signOut(auth).then(() => {})
            })
        })
        .catch(async (error) => {
          message.error(error.message, 5)
          navigate('/login')
          throw new Error(error.message)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <SpinLoading />
}

export default ChangeEmail
